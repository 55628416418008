import React, { useState, useEffect } from "react";
import { CloudConfig, useAdmin } from "../../context/admin";

import { Box } from "@mui/material";

import PromptForm from "./PromptForm";

export interface PromptDialogProps {
    promptAdmin?: string;
    busy: boolean;
    setBusy: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Answer({ busy, promptAdmin, setBusy }: PromptDialogProps) {
    const {
        profileCloudDoc,
        baseConfig,
        config,
        initialSetup,
        updatePrompt,
        updateBasePrompt,
        updatePromptTokens
    } = useAdmin();
    const [personaField, setPersona] = useState("");
    const [promptField, setPrompt] = useState("");
    const [tokenText, setTokenText] = useState("");
    const [cloudConfig, setCloudConfig] = useState<CloudConfig>(config.cloud);

    useEffect(() => {
        setCloudConfig(config.cloud)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileCloudDoc])

    function onChangeField(event: React.ChangeEvent<HTMLInputElement>): void {
        if (cloudConfig) {
            const tempProfile = cloudConfig;
            const field = event.target.id;
            if (field === "persona") {
                tempProfile.ask.persona = event.target.value;
                setPersona(event.target.value)
            }
            if (field === "prompt") {
                tempProfile.ask.prompt = event.target.value;
                setPrompt(event.target.value)
            }
            setCloudConfig(tempProfile);
        }
    }

    async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>): Promise<any>{
        event.preventDefault();
        setBusy(true);
        const data = new FormData(event.currentTarget);
        const persona = personaField || data.get("persona") as string;
        const prompt = promptField || data.get("prompt") as string;

        if (!profileCloudDoc?.exists() && promptAdmin && promptAdmin !== "(All Profiles)")
            await initialSetup(promptAdmin, baseConfig.cloud, "cloud");
        if (promptAdmin && promptAdmin !== "(All Profiles)")
            await updatePrompt(promptAdmin, "cloud", "ask", persona, prompt);
        if (promptAdmin && promptAdmin === "(All Profiles)")
            await updateBasePrompt("cloud", "ask", persona, prompt);
        setBusy(false);
    };

    async function onDeleteAdditionalPrompt(tokenId: string) {
        if (promptAdmin && cloudConfig) {
            const tokens = cloudConfig.ask.additionalPrompts
            const additionalPrompts = tokens.filter(token => token !== tokenId);
            await updatePromptTokens(promptAdmin, "cloud", "ask", additionalPrompts)
        }
    }

    async function onAddToken(event: React.SyntheticEvent) {
        const token = tokenText;
        if (profileCloudDoc?.exists() && promptAdmin && promptAdmin !== "(All Profiles)" && config)
            await initialSetup(promptAdmin, baseConfig.cloud, "cloud");
        if (promptAdmin) {
            const additionalPrompts = cloudConfig.ask?.additionalPrompts || [];
            additionalPrompts.push(token);
            await updatePromptTokens(promptAdmin, "cloud", "ask", additionalPrompts)
        }
        setTokenText("");
    }

    function onChangeToken(event: React.ChangeEvent<HTMLInputElement>) {
        setTokenText(event.target.value);
    }

    return (
        <Box>
            <PromptForm
                persona={cloudConfig?.ask?.persona}
                prompt={cloudConfig?.ask?.prompt}
                additionalPrompts={cloudConfig?.ask?.additionalPrompts}
                busy={busy}
                tokenText={tokenText}
                onAddToken={onAddToken}
                onChangeToken={onChangeToken}
                onChange={onChangeField}
                onDelete={onDeleteAdditionalPrompt}
                handleSubmit={handleSubmit}
            />
        </Box>
    );
}
