/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { AskResult, answer, formatPageNumber, updateDocument } from "./lib";
import { useApp, useUser } from "./context";
import { Collapsible, EditPromptButton, SxSpin } from "./components";
import * as firestore from "firebase/firestore";

import {
    Alert,
    Card,
    CardContent,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    TextareaAutosize,
    Tooltip,
    Typography
} from "@mui/material";

import {
    Delete as DeleteIcon,
    Sync as RefreshIcon
} from "@mui/icons-material";

export interface Props {
    data: AskResult;
    showQuestion?: boolean;
    profile?: string;
    docId?: string;
    questionId?: string;
    showDelete?: boolean;
}

export default ({ profile, data, docId, questionId, showDelete = true, showQuestion = true }: Props) => {
    const { user } = useUser();
    const { filter } = useApp();
    const [deleteHits, setExcludeHits] = useState<string[]>([]);
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState("");

    function toggle(id: string): void {
        if (deleteHits.includes(id))
            setExcludeHits(deleteHits.filter(c => c !== id));
        else
            setExcludeHits([...deleteHits, id]);
    }

    async function handleDelete() {
        if (questionId) {
            setBusy(true);
            await updateDocument({
                collection: `/profiles/${profile}/documents/${docId}/questions`,
                id: questionId,
                data: { answer: firestore.deleteField(), hits: firestore.deleteField() }
            });
            setBusy(false);
        }
    }

    async function onChangePromptAddition(event: React.SyntheticEvent, value: string): Promise<void> {
        if (questionId)
            await updateDocument({
                collection: `/profiles/${profile}/documents/${docId}/questions`,
                id: questionId,
                data: { promptAddition: value }
            });
    }

    function handleReAnswer(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        //todo: update the question from the change handler and then remove this piece of code
        data.hits.forEach(hit => {
            if (deleteHits.includes(hit.summary))
                hit.disabled = true;
            return hit;
        });
        handleAnswer();
    }

    async function handleAnswer() {
        if (profile && docId && questionId) {
            setBusy(true);
            await updateDocument({
                collection: `/profiles/${profile}/documents/${docId}/questions`,
                id: questionId,
                data: { hits: data.hits }
            });
            const { ok } = await answer({
                profile,
                document: docId,
                questions: [questionId],
                filter
            });
            if (ok)
                setExcludeHits([]);
            setError(ok ? "" : "Unable to process request, please try again or contact support for assistance.");
            setBusy(false);
        }
    }

    return (
        <Stack>
            {showQuestion && <p><b>Question:</b> {data.question}</p>}
            {showDelete && <Stack direction="row" display="flex" justifyContent="right">
                <EditPromptButton
                    prompt={data.promptAddition}
                    title="How to answer this question"
                    tooltip="Add instructions for answering this question"
                    placeholder="Additional instructions for answering this question"
                    onChange={onChangePromptAddition}
                />
                <Tooltip title="Update this answer">
                    <IconButton onClick={handleReAnswer} disabled={busy}>
                        <RefreshIcon sx={busy ? SxSpin : undefined} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete this answer">
                    <IconButton onClick={handleDelete} disabled={busy}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>}
            <p><b>Answer:</b> {data.answer}</p>
            <p>{data.hits?.length || 0} hits</p>
            {error && <Alert severity="error">{error}</Alert>}
            {data.hits?.map(reference => (
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <Typography variant="h5">{reference.heading}</Typography>
                        <Typography variant="body2" color="text.secondary">{reference.summary}</Typography>
                        <Typography variant="body2">{reference.file} (page&nbsp;{formatPageNumber(reference.page)})</Typography>
                        <Typography variant="body2">{reference.timestamp}</Typography>
                        <Tooltip title="Click this option and hit Update Answer above to omit this reference from the answer">
                            <FormControlLabel
                                label="Omit from answer"
                                disabled={false}
                                control={<Switch size="small" checked={deleteHits.includes(reference.summary)} onChange={() => toggle(reference.summary)} />}
                                sx={{ mt: 2 }}
                            />
                        </Tooltip>
                    </CardContent>
                </Card>
            ))}

            {user?.admin && data && <Collapsible title="Prompt (admin only)">
                <Typography variant="subtitle1" color="primary">Prompt</Typography>
                <TextareaAutosize value={data.prompt} maxRows={30} style={{ width: "100%"}} />

                <Typography variant="subtitle1" color="primary" sx={{ mt: 1 }}>Response</Typography>
                <TextareaAutosize value={data.response} maxRows={15} style={{ width: "100%"}} />

                <Typography variant="subtitle1" color="primary" sx={{ mt: 1 }}>Details</Typography>
                <TextareaAutosize
                    value={data ?
`
usage: ${data.usage} tokens
parse question: ${(data.elapsed?.embedding / 1000).toFixed(3)}s
query pinecone: ${(data.elapsed?.query / 1000).toFixed(3)}s
answer question: ${(data.elapsed?.prompt / 1000).toFixed(3)}s
${data.info}`.trim() : ""}
                maxRows={15}
                style={{ width: "100%"}}
            />
            </Collapsible>}
        </Stack>        
    );
};