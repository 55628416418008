import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useUser } from "./context";
import { reloadDocument } from "./lib";

import {
    Button
} from "@mui/material";

import { 
    EventRepeat as ReloadIcon
} from "@mui/icons-material"

export interface Props {
    id: string;
}

export default ({ id }: Props) => {
    const { user } = useUser();
    const navigate = useNavigate();

    async function handleReload() {
        if (user?.profile) {
            await reloadDocument({ profile: user?.profile, id });
            navigate(-1); // navigate to the parent folder for the document
        }
    }

    return (
        <Tooltip title="Reload this document">
            <Button
                onClick={handleReload}
            >
                <ReloadIcon fontSize="small" />
            </Button>
        </Tooltip>
    );
};
