import React from "react";
import { formatNumber, Document } from "./lib";
import { useUser, useDocument } from "./context";
import { Collapsible } from "./components";

import {
    Box,
    Stack,
    SxProps,
    TextareaAutosize,
    Theme,
    Typography
} from "@mui/material";

interface Prompt {
    prompt: string;
    result: string;
    model: string;
    temperature: number;
    tokens: number;
    elapsed: number;
    finish_reason: string;
    usage: number;
}

const promptPricePerToken = 0.003 / 1000;
const completionPricePerToken = 0.004 / 1000;

export interface Props {
    document: Document;
    sx?: SxProps<Theme>;
}

export default ({ document, sx }: Props) => {
    const { user } = useUser();
    const [doc, docLoading, docError] = useDocument(`/profiles/${user?.profile}/documents/${document.id}/debug`, "load");
    const prompts = doc?.data()?.prompts as Prompt[];

    const promptTokens = prompts ? prompts.reduce((sum, prompt) => sum + prompt.tokens, 0) : 0;
    const completionTokens = prompts ? prompts.reduce((sum, prompt) => sum + prompt.usage, 0) : 0;

    const promptCost = `$${(promptTokens * promptPricePerToken).toFixed(2)}`;
    const completionCost = `$${(completionTokens * completionPricePerToken).toFixed(2)}`;
    const totalCost = `$${(promptTokens * promptPricePerToken + completionTokens * completionPricePerToken).toFixed(2)}`;

    if (!prompts)
        return null;

    return (
        <Stack sx={sx}>
            <Box sx={{ mb: 1 }}>
                <Typography>{formatNumber(prompts.length)} prompts</Typography>
                <Typography>{formatNumber(document.pages)} pages</Typography>
                <Typography>{formatNumber(document.sections)} sections</Typography>
                <Typography>{formatNumber(document.words)} words</Typography>
            </Box>

            <Box sx={{ mb: 2 }}>
                <Typography>prompt: {formatNumber(promptTokens)} ({promptCost})</Typography>
                <Typography>completion: {formatNumber(completionTokens)} ({completionCost})</Typography>
                <Typography>total: ({totalCost})</Typography>
            </Box>

            {prompts.map(prompt => (
                <Collapsible key={prompt.prompt} title={`Prompt #${prompts.indexOf(prompt) + 1}`}>
                    <Typography variant="subtitle1" color="primary">Prompt</Typography>
                    <TextareaAutosize value={prompt.prompt} maxRows={30} style={{ width: "100%" }} />
                    
                    <Typography variant="subtitle1" color="primary" sx={{ mt: 1 }}>Response</Typography>
                    <TextareaAutosize value={prompt.result} maxRows={15} style={{ width: "100%" }} />

                    <Typography variant="subtitle1" color="primary" sx={{ mt: 1 }}>Details</Typography>
                    <TextareaAutosize value={`
model: ${prompt.model}
elapsed: ${prompt.elapsed < 60000 ? `${Math.round(prompt.elapsed / 1000)} seconds` : `${(prompt.elapsed / 60000).toFixed(1)} minutes` }
finish_reason: ${prompt.finish_reason}
prompt: ${formatNumber(prompt.tokens)} ($${(prompt.tokens * promptPricePerToken).toFixed(2)})
completion: ${formatNumber(prompt.usage)} ($${(prompt.usage * completionPricePerToken).toFixed(2)})
total: ($${(prompt.tokens * promptPricePerToken + prompt.usage * completionPricePerToken).toFixed(2)})
                    `.trim()} maxRows={15} style={{ width: "100%" }} />
                </Collapsible>
            ))}
        </Stack>
    );
}