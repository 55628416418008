import React from "react";
import { Document, Section } from "./lib";
import { useUser, useCollection } from "./context";
import { Collapsible } from "./components";
import { isEmpty } from "./lib";

import {
    Stack,
    SxProps,
    Theme,
    Typography
} from "@mui/material";

export interface Props {
    document: Document;
    sx?: SxProps<Theme>;
}

export default ({ document, sx }: Props) => {
    const { user } = useUser();
    const [collection] = useCollection(`/profiles/${user?.profile}/documents/${document.id}/sections`);
    const sections = collection?.docs.map(doc => ({ ...doc.data(), id: doc.id } as Section)) || [];

    if (!user?.profile)
        return null;

    return (
        <Stack sx={sx}>
            {sections.map(section => (
                <Collapsible
                    key={section.id}
                    title={
                        <Typography>{sections.indexOf(section) + 1}. {section.heading}{!isEmpty(section.questions) ? <Typography variant="caption" display="inline" color="primary" sx={{ ml: 1 }}>({section.questions.length} questions)</Typography> : ""}</Typography>
                    }
                >
                    <Typography display="inline">{section.text}</Typography>
                    <Typography variant="subtitle2" color="primary" sx={{ mt: 5 }}>SUMMARY</Typography>
                    <Typography sx={{ fontStyle: "italic" }}>{section.summary}</Typography>
                    {!isEmpty(section.questions) && (
                        <>
                        <Typography variant="subtitle2" color="primary" sx={{ mt: 2 }}>QUESTIONS</Typography>
                        <Typography sx={{ fontSize: "smaller" }}>
                            <ul>
                                {section.questions.map(obj => (<li key={obj.id}>{obj.question}</li>))}
                            </ul>
                        </Typography>
                        </>
                    )}
                    <Typography color="primary" sx={{ mt: 2, fontSize: "smaller" }}>PAGE {section.page}</Typography>
                </Collapsible>
            )
            )}
        </Stack>
    );
}