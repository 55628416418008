import { useApp } from "../context";
import { useDocument } from "./hooks";
import { ImporterConfig } from "./admin";
import { firestore as db } from "../firebase";
import * as firestore from "firebase/firestore";


export interface User {
    id: string;
    admin?: boolean;
    promptAdmin?: string;
    profile?: string;
    profiles?: string[];
}

export function useUser() {
    const { userId } = useApp();
    const [doc, loading, error] = useDocument("users", userId || "0");
    const user = doc ? { ...doc.data(), id: doc.id } as User : undefined;
    const [profileDoc] = useDocument(`profiles/${user?.profile}/config`, "importer");
    const profile = profileDoc ? { ...profileDoc.data() } as ImporterConfig : undefined;

    async function setUserProfile(profile: string) {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { profile }, { merge: true });
        }
    }

    async function setUserAdmin(promptAdmin: string) {
        if (userId) {
            const doc = firestore.doc(db, "users", userId);
            await firestore.setDoc(doc, { promptAdmin }, { merge: true });
        }
    }

    return {
        user,
        profile,
        loading,
        error,
        setUserAdmin,
        setUserProfile
    };
}
