export function formatNumber(value: number): string {
    return !isNaN(value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
}

export function isEmpty(obj: unknown): boolean {
    if (obj === null || obj === undefined)
        return true;
    else if (typeof obj === "string" || Array.isArray(obj))
        return obj.length === 0;
    else if (typeof obj === "object")
        return Object.keys(obj as object).length === 0;
    else
        return false;
}
