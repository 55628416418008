import { useCollection } from "./hooks";
import { useUser } from "../context";

import {
    createDocument,
    findDocument,
    updateDocument,
    formatDocument
} from "../lib";

export interface Profile {
    id: string;
    name: string;
    color?: string;
    deletedAt?: Date;
}

export function useProfile() {
    const { setUserProfile } = useUser();
    const [collection, loading, error] = useCollection("/profiles");

    const profiles = collection?.docs
        .map(doc => ({ ...formatDocument(doc.data()), id: doc.id } as Profile))
        .filter(doc => !doc.deletedAt)
        .sort((a, b) => a.name?.localeCompare(b.name)) || [];
    
    const i = profiles.findIndex(profile => profile.id === "default");
    if (i === -1)
        profiles.unshift({ id: "default", name: "(Default)" });
    else if (i !== 0)
        profiles.unshift(profiles.splice(i, 1)[0]);

    for (const profile of profiles)
        if (!profile.name)
            profile.name = profile.id;

    async function addProfile(name: string): Promise<string> {
        let id = await findDocument({ collection: "profiles", key: "name", value: name });
        if (!id)
            id = await createDocument({ collection: "profiles", id: name, data: { name } });
        else
            await updateDocument({ collection: "profiles", id, data: { deletedAt: undefined } });
        setUserProfile(id);
        return id;
    }

    async function deleteProfile(id: string): Promise<void> {
        debugger;
        if (id !== "default") {
            await updateDocument({ collection: "profiles", id, data: { deletedAt: new Date() } });
            setUserProfile(profiles[0].id);
        }
    }
        
    return {
        profiles,
        addProfile,
        deleteProfile,
        loading,
        error
    };
}
