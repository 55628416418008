import React, { useState, useEffect } from "react";
import { ImporterConfig, useAdmin } from "../../context/admin";

import { Box } from "@mui/material";

import PromptForm from "./PromptForm";

export interface PromptDialogProps {
    promptAdmin?: string;
    busy: boolean;
    setBusy: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Responses({ busy, promptAdmin, setBusy }: PromptDialogProps) {
    const {
        profileImporterDoc,
        baseConfig,
        config,
        initialSetup,
        updatePrompt,
        updateBasePrompt,
        updatePromptTokens
    } = useAdmin();
    const [personaField, setPersona] = useState("");
    const [promptField, setPrompt] = useState("");
    const [tokenText, setTokenText] = useState("");
    const [responsesConfig, setResponsesConfig] = useState<ImporterConfig>(config.importer);

    useEffect(() => {
        setResponsesConfig(config.importer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileImporterDoc]);

    function onChangeField(event: React.ChangeEvent<HTMLInputElement>): void {
        if (config) {
            const tempProfile = config.importer;
            const field = event.target.id;
            if (field === "persona") {
                tempProfile.responses.persona = event.target.value;
                setPersona(event.target.value)
            }
            if (field === "prompt") {
                tempProfile.responses.prompt = event.target.value;
                setPrompt(event.target.value)
            }
            setResponsesConfig(tempProfile);
        }
    }

    async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>): Promise<any>{
        event.preventDefault();
        setBusy(true);
        const data = new FormData(event.currentTarget);
        const persona = personaField || data.get("persona") as string;
        const prompt = promptField || data.get("prompt") as string;

        if (!profileImporterDoc?.exists() && promptAdmin && promptAdmin !== "(All Profiles)")
            await initialSetup(promptAdmin, baseConfig.importer, "importer");
        if (promptAdmin && promptAdmin !== "(All Profiles)")
            await updatePrompt(promptAdmin, "importer", "responses", persona, prompt);
        if (promptAdmin && promptAdmin === "(All Profiles)")
            await updateBasePrompt("importer", "responses", persona, prompt);
        setBusy(false);
    };

    async function onDeleteAdditionalPrompt(tokenId: string) {
        if (promptAdmin) {
            const tokens = responsesConfig.responses.additionalPrompts
            const additionalPrompts = tokens.filter(token => token !== tokenId);
            await updatePromptTokens(promptAdmin, "importer", "responses", additionalPrompts)
        }
    }

    async function onAddToken(event: React.SyntheticEvent) {
        const token = tokenText;
        if (profileImporterDoc?.exists() && promptAdmin && promptAdmin !== "(All Profiles)")
            await initialSetup(promptAdmin, baseConfig.importer, "importer");
        if (promptAdmin) {
            const additionalPrompts = responsesConfig.responses.additionalPrompts || [];
            additionalPrompts.push(token);
            await updatePromptTokens(promptAdmin, "importer", "responses", additionalPrompts)
        }
        setTokenText("");
    }

    function onChangeToken(event: React.ChangeEvent<HTMLInputElement>) {
        setTokenText(event.target.value);
    }

    return (
        <Box>
            <PromptForm
                persona={responsesConfig?.responses?.persona}
                prompt={responsesConfig?.responses?.prompt}
                additionalPrompts={responsesConfig?.responses?.additionalPrompts}
                busy={busy}
                tokenText={tokenText}
                onAddToken={onAddToken}
                onChangeToken={onChangeToken}
                onChange={onChangeField}
                onDelete={onDeleteAdditionalPrompt}
                handleSubmit={handleSubmit}
            />
        </Box>
    );
}
