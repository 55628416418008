import React from "react";
import LoginFormStructure from "./LoginFormStructure";

import {
    Box,
    Divider,
    TextField,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import {
    Google as GoogleIcon,
    Mail as MicrosoftIcon
} from "@mui/icons-material";

export interface AuthProps {
    busy: boolean;
    busyGoogle: boolean;
    error: string | undefined;
    password: string;
    onChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCloseAlert: () => void;
    handleSubmit: (event: React.ChangeEvent<HTMLFormElement>) => Promise<void>;
    handleOAuth: (type: "google" | "microsoft") => Promise<void>;
}

export default function AuthForm({ error, busy, busyGoogle, password, onChangePassword, handleCloseAlert, handleSubmit, handleOAuth}: AuthProps) {
    return (
        <LoginFormStructure error={error} handleCloseAlert={handleCloseAlert}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={onChangePassword}
                />

                <LoadingButton
                    id="password"
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={busy}
                    disabled={!password}
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </LoadingButton>

                <Divider>OR</Divider>

                <LoadingButton
                    id="google"
                    fullWidth
                    variant="contained"
                    loading={busyGoogle}
                    startIcon={<GoogleIcon />}
                    sx={{ mt: 3 }}
                    onClick={() => handleOAuth("google")}
                >
                    Log in with Google
                </LoadingButton>

                <LoadingButton
                    id="microsoft"
                    fullWidth
                    variant="contained"
                    loading={busyGoogle}
                    startIcon={<MicrosoftIcon />}
                    sx={{ mt: 3 }}
                    onClick={() => handleOAuth("microsoft")}
                >
                    Log in with Microsoft
                </LoadingButton>
            </Box>
        </LoginFormStructure>
    );
}