import React from "react";
import { useTheme } from "@mui/material/styles";

import {
    Avatar,
    SxProps,
    Theme
} from "@mui/material";

import {
    DescriptionOutlined as DocumentIcon,
    ErrorOutline as ErrorIcon,
    HourglassEmpty as PendingIcon,
    Upload as UploadingIcon
} from "@mui/icons-material";

import {
    Document
} from "./lib";

import {
    CircularProgressPercent
} from "./components";

export interface Props {
    document: Document;
    sx?: SxProps<Theme>;
}

export default ({ document, sx }: Props) => {
    const theme = useTheme();

    let text = "";
    let color = undefined;
    let icon = <DocumentIcon fontSize="small" />;
    if (document.status === "loading"  && document.page && document.pages) {
        text = `${document.page} of ${document.pages} pages read`;
        if (document.questions)
            text += `, ${document.questions} questions found`;
        icon = <CircularProgressPercent variant="determinate" value={document.progress} />;
    }
    else if (document.status === "ok") {
        text = `${document.pages} pages${document.questions ? `, ${document.questions} questions` : ""}`;
    }
    else if (document.status === "error" || document.status === "aborted") {
        text = document.error;
        color = "error";
        icon = <ErrorIcon color="error" fontSize="small" />;
    }
    else if (document.status === "uploading") {
        icon = <UploadingIcon fontSize="small" />;
    }
    else if (document.status === "pending" || document.status === "loading") {
        icon = <PendingIcon fontSize="small" />;
    }

    return <Avatar sx={{ bgcolor: theme.palette.primary.light }}>{icon}</Avatar>;
}