import React from "react";
import { Label } from "../DocumentFolder/context";

import {
    Chip,
    SxProps,
    Theme
} from "@mui/material";

export interface Props {
    label: Partial<Label>;
    sx?: SxProps<Theme>;
    onClick?: (event: React.SyntheticEvent) => void;
    onDelete?: (event: React.SyntheticEvent) => void;
}

export default ({ label, sx, onClick, onDelete }: Props) => (
    <Chip
        label={label.name}
        color="info"
        size="small"
        sx={sx}
        onClick={onClick}
        onDelete={onDelete}
    />
);
