import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import * as firebase from "firebase/auth";
import { auth } from "../firebase";

import { useAuth } from "../AuthContext";
import { useApp } from "../AppContext";

import EmailForm from "./EmailForm";
import FinishLoginForm from "./FinishLoginForm";

type Step = 'email-form' | 'finish-login-form';

export default function Login() {
    const navigate = useNavigate();
    const { login, loginWithOAuth } = useAuth();
    const { authenticated } = useApp();
    const [step, setStep] = React.useState<Step>("email-form");
    const [email, setEmail] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState("");
    const [busy, setBusy] = useState(false);
    const [busyGoogle, setBusyGoogle] = useState(false);

    useEffect(() => {
        if (authenticated)
            navigate("/")
    }, [authenticated, navigate]);

    async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>): Promise<void> {
        try {
            event.preventDefault();
            setBusy(true);
    
            const data = new FormData(event.currentTarget);
            const password = data.get("password") as string;
    
            await login(email, password)
            setBusy(false);
        }
        catch (err) {
            setError("Incorrect password or wrong provider. Try Login in with Google")
            setBusy(false);
        }
    };

    async function handleOAuth(type: "google" | "microsoft"): Promise<void> {
        setBusyGoogle(true);
        await loginWithOAuth(type);
        setBusyGoogle(false);
    };

    async function checkUser(event: React.ChangeEvent<HTMLFormElement>): Promise<any>{
       try {
        event.preventDefault();
        setBusy(true);
        const data = new FormData(event.currentTarget);
        const userEmail = data.get("email") as string;
        // const userCredentials = await firebase.fetchSignInMethodsForEmail(auth, userEmail);
        // if (userCredentials.length === 0) {
        //     setBusy(false);
        //     setError("User doesn't exists in Ikaun database. Please contact support for access.")
        //     return;
        // }
        setEmail(userEmail);
        setBusy(false);
        setStep("finish-login-form");
       }
       catch (err) {
            setError("Invalid email. Please enter a valid email address.")
            setBusy(false);
       }
    };

    function handleCloseAlert(): void {
        setError(undefined);
    }

    function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        setEmail(event.target.value);
    }

    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void {
        setPassword(event.target.value);
    }

    if (step === "email-form") {
        return (
            <EmailForm
                busy={busy}
                error={error}
                email={email}
                handleCloseAlert={handleCloseAlert}
                handleSubmit={checkUser}
                onChangeEmail={onChangeEmail}
            />
        );
    } else if (step === "finish-login-form") {
        return (
            <FinishLoginForm 
                busy={busy}
                busyGoogle={busyGoogle}
                error={error}
                password={password}
                handleCloseAlert={handleCloseAlert}
                handleSubmit={handleSubmit}
                handleOAuth={handleOAuth}
                onChangePassword={onChangePassword}
            />
        );
    }
    return null;
}