import * as React from "react";

import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionProps as MuiAccordionProps,
    AccordionSummary as MuiAccordionSummary,
    AccordionSummaryProps as MuiAccordionSummaryProps,
    SxProps,
    Theme,
    styled,
    useTheme
} from "@mui/material";

import {
    ArrowForwardIosSharp as ExpandIcon
} from "@mui/icons-material";

export interface Props {
    title: React.ReactNode;
    variant?: Variant;
    defaultExpanded?: boolean;
    key?: string;
    sx?: SxProps<Theme>;
    children: React.ReactNode;
}

export type Variant = "filled" | "outlined" | "default";

interface AccordionProps extends MuiAccordionProps {
    border?: string;
}

const Accordion = styled(({ border, ...props }: AccordionProps) => {
    return <MuiAccordion disableGutters elevation={0} square {...props} />
})(({ border }: AccordionProps) => ({
    border,
    "&:not(:last-child)": { borderBottom: 0 },
    "&:before": { display: "none" }
}));

interface AccordionSummaryProps extends MuiAccordionSummaryProps {
    backgroundColor?: string;
    border?: string;
}

const AccordionHeading = styled(({ backgroundColor, ...props }: AccordionSummaryProps) => {
    return (
        <MuiAccordionSummary
            expandIcon={<ExpandIcon sx={{ fontSize: "0.9rem" }} />}
            {...props}
        />
    );
})(({ theme, backgroundColor, border = "none" }: AccordionSummaryProps & { theme?: any }) => ({
    backgroundColor,
    flexDirection: "row-reverse",
    border,
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

export default ({ title, key, variant, defaultExpanded, sx, children }: Props) => {
    const theme = useTheme();
    const style = computeStyle(theme, variant);
    return (
        <Accordion
            key={key}
            defaultExpanded={defaultExpanded}
            border={style.border}
            sx={sx}
        >
            <AccordionHeading
                backgroundColor={style.heading.backgroundColor}
                border={style.heading.border}
            >
                {title}
            </AccordionHeading>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
}

interface AccordionStyle {
    border?: string;
    heading: {
        border?: string;
        backgroundColor?: string;    
    }
}

function computeStyle(theme: Theme, variant: Variant = "default"): AccordionStyle {
    const style: AccordionStyle = {
        heading: {}
    };
    if (variant === "outlined") {
        style.border = `1px solid ${theme.palette.divider}`;
        style.heading.backgroundColor = theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)";
    }
    else if (variant === "filled") {
        style.border = "none";
        style.heading.backgroundColor = theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)";
    }
    else if (variant === "default") {
        style.border = "none";
        style.heading.backgroundColor = "transparent";    
    }
    return style;
}