export interface DeleteDocumentResult {
    ok: boolean;
}

export interface DeleteDocumentOptions {
    profile: string;
    id: string;
}

export async function deleteDocument({ profile, id }: DeleteDocumentOptions): Promise<DeleteDocumentResult> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/document/${id}`;
    const response = await fetch(url, { method: "DELETE" });
    return { ok: response.ok }
}
