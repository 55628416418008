import React, { useState, useEffect } from "react";
import { useApp, useBreakpoint, useCollection, useUser } from "./context";
import { ask, AskResult, ChatMessage, formatPageNumber, ReferenceDocument } from "./lib";
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
    Stack,
    SxProps,
    TextField,
    Theme
} from "@mui/material";

import { 
    SmartToy as SmartToyIcon,
    Person as PersonIcon,
    Send as SendIcon,
    RestartAlt as RestartIcon
} from "@mui/icons-material"

import { Collapsible, DocumentBreadcrumbs } from "./components";
import ChatHome from "./ChatHome";
import Home from "./Home";

export interface Props {
    sx?: SxProps<Theme>
}

export default function Chat({ sx }: Props) {
    const { user } = useUser();
    const { filter } = useApp();
    const { breakpoint } = useBreakpoint();
    const [collection, collectionLoading] = useCollection(`/profiles/${user?.profile}/documents`);
    const [input, setInput] = useState("");
    const [response, setResponse] = useState<AskResult | undefined>();
    const [chat, setChat] = useState<ChatMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const [hits, setHits] = useState<ReferenceDocument[] | undefined>(undefined);

    useEffect(() => {
        updateScroll();
    }, [chat, input])

    async function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>): Promise<void> {
        event.preventDefault();
        if (user?.profile) {
            const question = input;
            setInput("");
            setLoading(true);
            chat.push({ role: "user", content: question });
            const data = await ask(question, user.profile, chat, filter);
            if (data) {
                console.log(data.chat);
                setChat(data.chat.filter(message => message.role !== "system"));
            }
            if (!hits || (data?.hits && data?.hits.length >= 1))
                setHits(data?.hits);
            setResponse(data);
            setLoading(false);
        }
    }

    function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        setInput(event.target.value);
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    }

    function resetChat(): void {
        setChat([]);
        setResponse(undefined);
        setHits(undefined);
    }

    function updateScroll(){
        const element = document.getElementById("list");
        if (element)
            element.scrollTop = element.scrollHeight;
    }

    function handleHeight(): number {
        if (breakpoint) {
            if (breakpoint === "xs" && (!chat || chat.length <= 0)) return 155
            if (breakpoint === "xs" && !(!chat || chat.length <= 0)) return 595
            if (breakpoint === "sm" && (!chat || chat.length <= 0)) return 160
            if (breakpoint === "sm" && !(!chat || chat.length <= 0)) return 600
            if (breakpoint === "md" && (!chat || chat.length <= 0)) return 165
            if (breakpoint === "md" && !(!chat || chat.length <= 0)) return 605
            if (breakpoint === "lg" && (!chat || chat.length <= 0)) return 170
            if (breakpoint === "lg" && !(!chat || chat.length <= 0)) return 610
            if (breakpoint === "xl" && (!chat || chat.length <= 0)) return 310
            if (breakpoint === "xl" && !(!chat || chat.length <= 0)) return 730
        }
        return 610
    }

    function handleWidth(): number | string {
        if (breakpoint) {
            if (breakpoint === "xs") return "100%"
        }
        return 1200
    }

    function handleBarWidth(): number | string {
        if (breakpoint) {
            if (breakpoint === "xs") return "100%"
        }
        return 885
    }

    function parseMessage(chat: {role: string, content: string}): React.ReactNode {
        chat.content = chat.content.replace("SUMMARY:", "").trim();
        const parse: string[] | undefined = chat.content.split("\n") || undefined;
        const parsed = parse?.filter(text => text !== "");
        if (parsed)
            return (
                parsed?.map(text => (
                    text.startsWith("-") ?
                    <ul>
                        <li>{text.replace("-", "") + "\n"}</li>
                    </ul> : 
                    `${text}\n`
                ))
            );
        else
            return (
                chat.content
            );
    };

    if (collectionLoading) {
        return (
            <Stack>
                <DocumentBreadcrumbs />
                <LinearProgress sx={{ mt: 2 }} />
            </Stack>
        );
    }

    if (!collectionLoading && (!collection?.docs || collection?.docs?.length <= 0))
        return (
            <Home />
        );
    else
        return (
            <Grid container spacing={2} direction="row" sx={{ width: handleWidth() }}>
                <Grid item xs={12} lg={9} xl={9}> 
                    {(!chat || chat.length <= 0) && (
                        <ChatHome />
                    )}
                    <List id="list" sx={{ height: handleHeight(), overflowY: "auto" }}>
                        {chat && chat.length >= 1 && chat.map(messages => (
                            <>
                                <ListItem
                                    sx={{ bgcolor: messages.role === "user" ? "white" : "#f6f6f6", lineHeight: 3}}
                                    alignItems="flex-start"
                                >
                                    <ListItemAvatar>
                                        <Avatar variant="square" sx={{ bgcolor: "#004389" }}>
                                            {messages.role === "assistant" && <SmartToyIcon />}
                                            {messages.role === "user" && <PersonIcon />}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{ textAlign: "justify" }}
                                        primary={messages.role === "assistant" ? <b>Assistant</b> : <b>You</b>}
                                        secondaryTypographyProps={{ sx: { lineHeight: 2, wordWrap: "break-word", whiteSpace: "pre-wrap" } }}
                                        secondary={parseMessage(messages)}
                                    />
                                </ListItem>
                            </>
                        ))}
                        <br/>
                        {loading && <LinearProgress color="primary" />} 
                    </List>
                </Grid>
                <Grid item xs={12} lg={3} xl={3} sx={{ height: handleHeight(), overflowY: "auto" }}>
                    {response && (
                        <Collapsible
                            title="Hits"
                            variant="filled"
                        >
                            <List>
                                {hits?.map(reference => (
                                    <Card sx={{ mt: 2 }}>
                                        <CardContent>
                                            <Typography variant="h5">{reference.heading}</Typography>
                                            <Typography variant="body2" color="text.secondary">{reference.summary}</Typography>
                                            <Typography variant="body2">{reference.file} (page&nbsp;{formatPageNumber(reference.page)})</Typography>
                                            <Typography variant="body2">{reference.timestamp}</Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </List>
                        </Collapsible>
                    )}
                </Grid>
                <Grid container direction="column">
                    <Grid item>
                        <TextField
                            label="Ask about any previous RFP response"
                            variant="outlined"
                            rows={5}
                            sx={{ width: handleBarWidth(), marginLeft: 1 }}
                            value={input}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {chat && chat.length >= 1 && (
                                            <Tooltip title="Restart Chat">
                                                <IconButton sx={{ marginRight: 0.5 }} onClick={resetChat} edge="end">
                                                    <RestartIcon color="primary" fontSize="medium" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <IconButton onClick={handleSubmit} edge="end">
                                            <SendIcon color="primary" fontSize="medium" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
}
