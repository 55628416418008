import React from "react";
import SplitPane from "./SplitPane";
import { useTheme } from "@mui/material/styles";
import DocumentBreadcrumbs from "./DocumentBreadcrumbs";

import {
    Stack,
    SxProps,
    Theme
} from "@mui/material";

export interface Props {
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
}

export default ({ children, sx }: Props) => {
    const theme = useTheme();
    return (
        <Stack direction="column">
            <DocumentBreadcrumbs />
            <SplitPane sx={{ bgcolor: "#eee", p: 1, mt: 2, mb: 2, ...sx }}>
                <Stack direction="row">
                    {children}
                </Stack>
            </SplitPane>
        </Stack>
    );
}