import { useLocation } from "react-router-dom";

export interface DocumentPath {
    pathname?: string;
    filename?: string;
    folder?: FolderType;
}

export type FolderType = "requests" | "responses";

export function useDocumentPath(): DocumentPath {
    const location = useLocation();
    if (location.pathname.startsWith("/documents/")) {
        const path = location.pathname.slice("/documents/".length);
        let folder: FolderType | undefined = undefined;
        if (path.startsWith("requests/"))
            folder = "requests";
        else if (path.startsWith("responses/"))
            folder = "responses";

        if (path.endsWith("/"))
            return {
                pathname: path.slice(0, -1),
                folder
            };
        else
            return {
                pathname: path.slice(0, path.lastIndexOf("/")),
                filename: path.slice(path.lastIndexOf("/") + 1),
                folder
            };
    }
    else {
        return {};
    }
}