import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApp } from "./context";

import {
    Box,
    Button,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";

import {
    FileUploadButton
} from "./components";

export default function Home() {
    const navigate = useNavigate();
    const { setSideBarOpen } = useApp();
    const [activeStep, setActiveStep] = useState(0);

    const steps = [
        {
            label: "Start building your searchable index",
            description: "Upload your first RFP response document to start building a searchable index of your prior RFP responses. IKA will read the document and use it to answer your questions.",
            action: (
                <FileUploadButton
                    folder="responses"
                    label="Upload RFP Response Document"
                    onComplete={() => {
                        setSideBarOpen(true);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                    onError={(error, message) => { /*setError(message)*/}}
                    sx={{ mt: 2 }}
                />
            )
        },
        {
            label: "Ask a question",
            description: "Try out the index by asking a question to the right. IKA will search the index and return the most relevant answers and summarize.",
            action: (
                <Button
                variant="contained"
                onClick={() => {
                    setSideBarOpen(false);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
                sx={{ mt: 2, mr: 1 }}
            >
                <Typography>Continue</Typography>
            </Button>

            )
        },
        {
            label: "Respond to a new RFP Request",
            description: "Upload your first RFP request document. IKA will review the document in detail, extracting questions which you can then answer using the searchable index.",
            action: (
                <FileUploadButton
                    folder="requests"
                    label="Upload RFP Request Document"
                    onComplete={() => {
                        navigate("/documents/requests/");
                    }}
                    onError={(error, message) => { /*setError(message)*/}}
                    sx={{ mt: 2, mr: 1 }}
                />
            )

        }
    ];
    
    return (
        <Paper elevation={0}>
            <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>Get Started</Typography>
            <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map(step => (
                        <Step key={step.label}>
                            <StepLabel>
                                <Typography variant="h6">{step.label}</Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    {step.action || (
                                        <Button
                                            variant="contained"
                                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                                            sx={{ mt: 2 }}
                                        >
                                            <Typography>Continue</Typography>
                                        </Button>
                                    )}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Paper>
    );
};
