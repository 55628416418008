// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyB6vquS93D85lD22JjJif6Le0mYjP1X5fw",
//     authDomain: "ikaun1.firebaseapp.com",
//     projectId: "ikaun1",
//     storageBucket: "ikaun1.appspot.com",
//     messagingSenderId: "847081553151",
//     appId: "1:847081553151:web:2703da422a9fe8a4efa0ca",
//     measurementId: "G-SL676MWE59"
// };

const firebaseConfig = {
    apiKey: "AIzaSyDZ3zhwcne_63Nynbka220-7c-hsEPchfo",
    authDomain: "ikaun2.firebaseapp.com",
    projectId: "ikaun2",
    storageBucket: "ikaun2.appspot.com",
    messagingSenderId: "962218649192",
    appId: "1:962218649192:web:da73ccc14d52b9323cfddd",
    measurementId: "G-XGZ2GTB8M8"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);