import React, { useState } from "react";
import { useUser } from "./context";
import { hideQuestions } from "./lib";

import {
    IconButton,
    Tooltip
} from "@mui/material";

import {
    Delete as DeleteIcon
} from "@mui/icons-material";

export interface Props {
    docId: string;
    questions: string[];
}

export default ({ docId, questions }: Props) => {
    const { user } = useUser();
    const [busy, setBusy] = useState(false);

    async function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setBusy(true);
        await hideQuestions({
            profile: user?.profile || "",
            docId,
            questions
        });
        setBusy(false);
    }

    return (
        <Tooltip title="Hide selected questions">
            <IconButton disabled={questions.length === 0 || busy} onClick={handleClick}>
                <DeleteIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}
