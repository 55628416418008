/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useUser, useCollection } from "./context";
import { AskResult, Document, updateDocument } from "./lib";
import { Collapsible, EditPromptButton } from "../components";
import Answered from "./Answered";
import Unanswered from "./Unanswered";

import {
    Box,
    Chip,
    Stack,
    TextareaAutosize,
    Typography
} from "@mui/material";

export interface QuestionDocument extends AskResult {
    id: string;
    page: number;
    hidden: boolean;
}

export interface Props {
    document: Document;
}

export default ({ document }: Props) => {
    const { user } = useUser();
    const [collection] = useCollection(`/profiles/${user?.profile}/documents/${document.id}/questions`);

    const unanswered = collection?.docs
        .map(doc => ({ ...doc.data(), id: doc.id } as QuestionDocument))
        .filter(question => !question.answer && !question.hidden)
        .sort((a, b) => a.id?.localeCompare(b.id)) || [];

    const answered = collection?.docs
        .map(doc => ({ ...doc.data(), id: doc.id } as QuestionDocument))
        .filter(question => !!question.answer && !question.hidden)
        .sort((a, b) => a.id?.localeCompare(b.id)) || [];

    const hidden = collection?.docs
        .map(doc => ({ ...doc.data(), id: doc.id } as QuestionDocument))
        .filter(question => question.hidden)
        .sort((a, b) => a.id?.localeCompare(b.id)) || [];

    async function onChangePromptAddition(event: React.SyntheticEvent, value: string): Promise<void> {
        event.preventDefault();
        await updateDocument({
            collection: `/profiles/${user?.profile}/documents`,
            id: document.id,
            data: { promptAddition: value }
        });
    }

    return (
        <Stack>
            <Box
                sx={{
                    mt: 2,
                    mb: 2,
                    borderBottom: 1,
                    borderColor: "divider"
                }}
            >
                <Stack direction="row" display="flex" justifyContent="right">
                    <EditPromptButton
                        prompt={document.promptAddition}
                        title="How to answer all questions in this document"
                        tooltip="Add instructions for answering all questions within this document"
                        placeholder="Additional instructions for answering questions within this document"
                        onChange={onChangePromptAddition}
                    />
                </Stack>
            </Box>

            {unanswered.length === 0 && (
            <Answered docId={document.id} questions={answered} />
            )}

            {unanswered.length > 0 && (
            <>
            <Collapsible
                title={
                    <Stack direction="row">
                        <Typography>Answered</Typography>
                        <Chip label={answered.length} size="small" sx={{ ml: 1, position: "relative", top: -3 }} />
                    </Stack>
                }
                variant="filled"
                defaultExpanded
            >
                <Answered docId={document.id} questions={answered} />
            </Collapsible>

            <Collapsible
                title={
                    <Stack direction="row">
                        <Typography>Unanswered</Typography>
                        <Chip label={unanswered.length} size="small" sx={{ ml: 1, position: "relative", top: -3 }} />
                    </Stack>
                }
                variant="filled"
                defaultExpanded
            >
                <Unanswered docId={document.id} questions={unanswered} />
            </Collapsible>
            </>
            )}

            {hidden.length > 0 && (
            <Collapsible
                title={
                    <Stack direction="row">
                        <Typography>Hidden</Typography>
                        <Chip label={hidden.length} size="small" sx={{ ml: 1, position: "relative", top: -3 }} />
                    </Stack>
                }
                variant="filled"
            >
                <TextareaAutosize value={hidden.map(question => question.question).join("\n")} minRows={10} style={{ width: "100%"}} />
            </Collapsible>
            )}

        </Stack>
    );
};
