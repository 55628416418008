import React from "react";
import { Label as ILabel } from "./context";
import Label from "./Label";

import {
    Box,
    SxProps,
    Theme
} from "@mui/material";

export interface Props {
    labels: ILabel[];
    sx?: SxProps<Theme>;
    display?: "inline" | "block" | "none";
    onClick?: (event: React.SyntheticEvent, id: string) => void;
    onDelete?: (event: React.SyntheticEvent, id: string) => void;
}

export default ({ labels, display, sx, onClick = () => {}, onDelete }: Props) => (
    labels instanceof Array ? (
        <Box display={display} sx={sx}>
            {labels
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map(label => (
                    <Label
                        key={label.id}
                        label={label}
                        sx={{ mr: 1, mb: 1 }}
                        onClick={event => onClick(event, label.id)}
                        onDelete={onDelete ? event => onDelete(event, label.id) : undefined}
                    />
                ))
            }
        </Box>
    ) : null
);
