import React from "react";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export interface Props extends CircularProgressProps { 
     value: number;
}

export default (props: Props) => (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" size={28} sx={{ color: "#fff" }} {...props} />
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography variant="caption" fontSize="xx-small">{`${Math.round(props.value)}%`}</Typography>
        </Box>
    </Box>
);
