import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { capitalize } from "./lib";
import { DocumentBar } from "./components";

import {
    List,
    ListItem,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material";

import {
    FolderOutlined as FolderIcon
} from "@mui/icons-material";


export default () => {
    const theme = useTheme();
    return (
        <Stack>
            <DocumentBar />
            <List>
                {["requests", "responses"].map(folder => (
                    <ListItem key="requests" disablePadding>
                        <ListItemButton component={Link} to={`${folder}/`}>
                            <FolderIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
                            <Typography noWrap>{capitalize(folder)}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
};