import React, { useState } from "react";

import { auth } from "../firebase";
import { firestore as db } from "../firebase";

import * as firebase from "firebase/auth";
import * as firestore from "firebase/firestore";

import {
    createUser,
    deleteUser,
    editUser,
    FirebaseUser
} from "../lib";

import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputLabel,
    IconButton,
    ListItemText,
    MenuItem,
    MenuList,
    Select,
    Stack,
    OutlinedInput,
    Tab,
    Tabs,
    Typography,
    TextField,
    SelectChangeEvent
} from "@mui/material";

import {
    AddBox as AddIcon,
    DeleteOutline as DeleteIcon,
    Edit as EditIcon
} from "@mui/icons-material";

import {
    ConfirmDialog,
} from "./components";

import LoadingButton from "@mui/lab/LoadingButton";
import { useProfile, useUser } from "../context";

export interface Props {
    open: boolean;
    users?: FirebaseUser[];
    onClose: (event: React.SyntheticEvent) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export type AddUserTab = "google" |  "microsoft" | "password";

export default function AddUserDialog({ users, open, onClose }: Props) {
    const { user } = useUser();
    const { profiles } = useProfile();
    const [busy, setBusy] = useState(false);
    const [confirmDeleteUser, setConfirmDeleteUser] = useState("");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [inputDialogOpen, setInputDialogOpen] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);
    const [admin, setAdmin] = useState(false);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [userProfiles, setUserProfiles] = useState<string[]>([]);
    const [tab, setTab] = useState<number>(0)
    const [action, setAction] = useState<"create" | "edit">("create");
    const [showTab, setShowTab] = useState<AddUserTab>("password");
    const [authId, setAuthId] = useState("");

    function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        setEmail(event.target.value);
    }

    function onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
        setName(event.target.value);
    }

    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void {
        setPassword(event.target.value);
    }

    function handleTab(event: React.SyntheticEvent, newValue: number) {
        setTab(newValue);
    }

    function onChangeProfile(event: SelectChangeEvent<string[]>): void {
        const { target: { value }, } = event;
        setUserProfiles(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    function handleAddUser(event: React.SyntheticEvent) {
        setEmail("");
        setName("");
        setAuthId("");
        setUserProfiles([]);
        setAdmin(false);
        setInputDialogOpen(true);
        setAction("create");
    }

    function handleDeleteUser(event: React.SyntheticEvent, uid: string) {
        event.stopPropagation();
        setDeleteDialogOpen(true);
        setConfirmDeleteUser(uid);
    }

    async function handleEditUser(event: React.SyntheticEvent, userRef: FirebaseUser) {
        event.stopPropagation();
        const docRef = firestore.doc(db, "users", userRef.uid);
        const doc = await firestore.getDoc(docRef);
        const data = doc.data();
        setEmail(userRef.email);
        setName(userRef.displayName);
        setUserProfiles(data?.profiles as string[] || []);
        setAdmin(data?.admin as boolean || false);
        setAuthId(userRef.uid);
        setAction("edit");
        setShowTab("password");
        setInputDialogOpen(true);
    }

    async function handleConfirmDeleteProfile(event: React.SyntheticEvent, confirmed: boolean) {
        if (confirmed && user?.profile) {
            await deleteUser(user?.profile, confirmDeleteUser);
            setConfirmDeleteUser("");
        }
        setDeleteDialogOpen(false);
    }


    async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>, uid?: string): Promise<any>{
        try {
            event.preventDefault();
            setError(undefined);
            setBusy(true);
            let userId = authId; 
            if (action === "create" && user?.profile) {
                const credentials = await firebase.fetchSignInMethodsForEmail(auth, email);
                if (credentials.length >= 1) {
                    setError("Someone already has that email.");
                    setBusy(false);
                    return;
                }
                const firebaseUser = await createUser(user?.profile, email, showTab, name, password);
                userId = firebaseUser.uid;
            }
            else if (action === "edit" && user?.profile && userId && (email || name || password)) {
                await editUser(user.profile, email, userId, name, password)
            }
            if (userId) {
                const doc = firestore.doc(db, "users", userId);
                await firestore.setDoc(doc, { lastSeen: new Date(), admin, profiles: userProfiles }, { merge: true });
            }
            setAction("create");
            setBusy(false);
            setInputDialogOpen(false)
        }
        catch (err) {
             setError("Invalid email. Please enter a valid email address.")
             setBusy(false);
        }
     };

    return (
        <Box>
            {open && (
                <>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Manage Users</Typography>
                        <IconButton edge="end" onClick={handleAddUser}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                    <MenuList>
                        {users?.map(user => (
                            <MenuItem key={user.uid} disableRipple>
                                <Typography display="block" width="100%">{user.displayName ? `${user.displayName} | ${user.email}` : user.email}</Typography>
                                <IconButton sx={{ marginRight: 0.5 }} edge="end" onClick={event => handleEditUser(event, user)}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton edge="end" onClick={event => handleDeleteUser(event, user.uid)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </MenuItem>
                        ))}
                    </MenuList>
                    <Dialog open={inputDialogOpen} onClose={() => setInputDialogOpen(false)}>
                        <DialogTitle>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">{action === "create" ? "Add User" : "Edit User"}</Typography>
                            </Stack>
                        </DialogTitle>
                        <Tabs value={tab} onChange={handleTab}>
                            <Tab label="Email / Password" value={0} onClick={() => setShowTab("password")} />
                            {action !== "edit" && (<Tab label="Google Provider" value={1} onClick={() => setShowTab("google")} />)}
                            {action !== "edit" && (<Tab label="Microsoft Provider" value={2} onClick={() => setShowTab("microsoft")} />)}
                        </Tabs>
                        <DialogContent>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    value={name}
                                    autoComplete="name"
                                    onChange={onChangeName}
                                    autoFocus
                                />

                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={email}
                                    autoComplete="email"
                                    onChange={onChangeEmail}
                                    autoFocus
                                />

                                {showTab === "password" && (
                                    <TextField
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                    onChange={onChangePassword}
                                    autoFocus
                                />)}
                                <br/>
                                <br/>
                                <FormControl fullWidth>
                                    <InputLabel id="profiles-label">Profiles</InputLabel>
                                    <Select
                                        labelId="proflies-label"
                                        multiple
                                        value={userProfiles}
                                        onChange={onChangeProfile}
                                        input={<OutlinedInput label="Name" />}
                                        renderValue={(selected: string[]) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {profiles.map(profile => (
                                            <MenuItem key={profile.id} value={profile.name}>
                                                <Checkbox checked={userProfiles.indexOf(profile.name) > -1} />
                                                <ListItemText primary={profile.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <br/>
                                <br/>

                                <FormControlLabel 
                                    control={<Checkbox
                                        size="small"
                                        checked={admin}
                                        onClick={() => setAdmin(!admin)}
                                        sx={{ display: "flex", justifyContent: "flex-start"}}
                                    />}
                                    label="Admin"
                                    sx={{ display: "flex", justifyContent: "flex-start" }}
                                />
                                

                                <LoadingButton
                                    id="email"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    loading={busy}
                                    disabled={!email && !userProfiles}
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </LoadingButton>
                                {error && <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {error}
                                </Alert>}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setInputDialogOpen(false)}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <ConfirmDialog
                        title="Delete User"
                        message="Are you sure you want to delete this user?"
                        open={deleteDialogOpen}
                        onClose={(event, confirmed) => handleConfirmDeleteProfile(event, confirmed)}
                    />
                </>
            )}
        </Box>
    );
}
