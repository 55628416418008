import { firestore } from "../firebase";
import { collection, doc } from "firebase/firestore";
import * as hooks from "react-firebase-hooks/firestore";

export function useCollection(name: string) {
    return hooks.useCollection(collection(firestore, name), { snapshotListenOptions: { includeMetadataChanges: true }});
}

export function useDocument(path: string, id: string) {
    return hooks.useDocument(doc(firestore, path, id), { snapshotListenOptions: { includeMetadataChanges: true }});
}
