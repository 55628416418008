import React, { useState } from "react";
import ManageProfilesDialog from "./ManageProfilesDialog";
import ManageUsersDialog from "./ManageUsersDialog";

import {
    Box,
    Stack,
    Tab,
    Tabs
} from "@mui/material";

import { useUser } from "../context";
import { FirebaseUser, getUsers } from "../lib";
import Prompts from "./Prompts";

export default function Admin() {
    const { user } = useUser();
    const [open, setOpen] = useState(true);
    const [showUserDialog, setShowUserDialog] = useState(false);
    const [showPromptDialog, setShowPromptDialog] = useState(false);
    const [tab, setTab] = useState<number>(0);
    const [users, setUsers] = useState<FirebaseUser[]>();

    async function onClickManageUsers() {
        setOpen(false)
        setShowPromptDialog(false)
        if (user?.profile) {
            const response = await getUsers(user.profile);
            setUsers(response.users);
            setShowUserDialog(true);
        }
    }

    function onClickManageProfiles() {
        setOpen(true)
        setShowPromptDialog(false)
        setShowUserDialog(false)
    }

    function onClickManagePrompts() {
        setShowPromptDialog(true)
        setOpen(false)
        setShowUserDialog(false)
    }

    function handleChange(event: React.SyntheticEvent, newValue: number) {
        setTab(newValue);
    }

    return (
        <Box>
            <Stack direction="row" spacing={4}>
                <Tabs value={tab} orientation="vertical" onChange={handleChange}>
                    <Tab label="Profiles" value={0} onClick={onClickManageProfiles} />
                    <Tab label="Users" value={1} onClick={onClickManageUsers} />
                    <Tab label="Prompts" value={2} onClick={onClickManagePrompts} />
                </Tabs>
                <ManageProfilesDialog
                    open={open}
                    onClose={() => setOpen(false)}
                />
                <ManageUsersDialog
                    users={users}
                    open={showUserDialog}
                    onClose={() => setShowUserDialog(false)}
                />
                { user && <Prompts user={user} open={showPromptDialog} /> }
            </Stack>
        </Box>
    );
}