import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { FolderOutlined as FolderIcon } from "@mui/icons-material";
import { capitalize, Document } from "../lib";
import { useUser, useDocument, useDocumentPath } from "./context";

export default () => {
    const { user } = useUser();
    const { pathname, filename } = useDocumentPath();

    const [doc] = useDocument(`/profiles/${user?.profile}/documents`, filename || "default");
    const document = { ...doc?.data(), id: doc?.id, profile: user?.profile } as unknown as Document;

    if (document.name && document.folder)
        return (
            <Breadcrumbs>
                <FolderIcon fontSize="small" />
                <Typography noWrap>{capitalize(document.folder)}</Typography>
                <Typography noWrap>{document.name}</Typography>
            </Breadcrumbs>
        );
    else
        return (
            <Breadcrumbs>
                <FolderIcon fontSize="small" />            
            </Breadcrumbs>
        );
};
