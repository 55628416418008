import React, { useState, useEffect } from "react";
import { ImporterConfig, useAdmin } from "../../context/admin";

import {
    Box,
    FormControlLabel,
    Switch,
} from "@mui/material";

import PromptForm from "./PromptForm";

export interface PromptDialogProps {
    promptAdmin?: string;
    busy: boolean;
    setBusy: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Requests({ busy, promptAdmin, setBusy }: PromptDialogProps) {
    const {
        profileImporter,
        profileImporterDoc,
        baseConfig,
        config,
        updateRequestFeature,
        initialSetup,
        updatePrompt,
        updateBasePrompt,
        updatePromptTokens
    } = useAdmin();
    const [personaField, setPersona] = useState("");
    const [promptField, setPrompt] = useState("");
    const [tokenText, setTokenText] = useState("");
    const [requestFeature, setRequestFeature] = useState<boolean>(profileImporter?.requests?.enabled  || false);
    const [requestsConfig, setRequestsConfig] = useState<ImporterConfig>(config.importer);

    useEffect(() => {
        setRequestsConfig(config.importer)
        setRequestFeature(config?.importer?.requests?.enabled || false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileImporterDoc])

    function onChangeField(event: React.ChangeEvent<HTMLInputElement>): void {
        if (config) {
            const tempProfile = config.importer;
            const field = event.target.id;
            if (field === "persona") {
                tempProfile.requests.persona = event.target.value;
                setPersona(event.target.value)
            }
            if (field === "prompt") {
                tempProfile.requests.prompt = event.target.value;
                setPrompt(event.target.value)
            }
            setRequestsConfig(tempProfile);
        }
    }

    async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>): Promise<any>{
        event.preventDefault();
        setBusy(true);
        const data = new FormData(event.currentTarget);
        const persona = personaField || data.get("persona") as string;
        const prompt = promptField || data.get("prompt") as string;

        if (!profileImporterDoc?.exists() && promptAdmin && promptAdmin !== "(All Profiles)")
            await initialSetup(promptAdmin, baseConfig.importer, "importer");
        if (promptAdmin && promptAdmin !== "(All Profiles)")
            await updatePrompt(promptAdmin, "importer", "requests", persona, prompt);
        if (promptAdmin && promptAdmin === "(All Profiles)")
            await updateBasePrompt("importer", "requests", persona, prompt);
        setBusy(false);
    };

    async function onDeleteAdditionalPrompt(tokenId: string) {
        if (promptAdmin) {
            const tokens = requestsConfig.requests.additionalPrompts
            const additionalPrompts = tokens.filter(token => token !== tokenId);
            await updatePromptTokens(promptAdmin, "importer", "requests", additionalPrompts)
        }
    }

    async function onAddToken(event: React.SyntheticEvent) {
        const token = tokenText;
        if (profileImporterDoc?.exists() && promptAdmin && promptAdmin !== "(All Profiles)" && config)
            await initialSetup(promptAdmin, baseConfig.importer, "importer");
        if (promptAdmin) {
            const additionalPrompts = requestsConfig.requests.additionalPrompts || [];
            additionalPrompts.push(token);
            await updatePromptTokens(promptAdmin, "importer", "requests", additionalPrompts)
        }
        setTokenText("");
    }

    function onChangeToken(event: React.ChangeEvent<HTMLInputElement>) {
        setTokenText(event.target.value);
    }

    async function onChangeRequestFeature(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        if (!profileImporter?.requests && promptAdmin && promptAdmin !== "(All Profiles)" && config)
            await initialSetup(promptAdmin, baseConfig.importer, "importer");
        if (promptAdmin)
            await updateRequestFeature(promptAdmin, checked)
    }

    return (
        <Box>
            {promptAdmin !== "(All Profiles)" && (
                <FormControlLabel 
                    label="Activate Requests BETA feature"
                    disabled={false}
                    control={<Switch size="small" checked={requestFeature} onChange={onChangeRequestFeature} />}
                />
            )}
            {(requestFeature || promptAdmin === "(All Profiles)") && (
                <PromptForm
                    persona={requestsConfig?.requests?.persona}
                    prompt={requestsConfig?.requests?.prompt}
                    additionalPrompts={requestsConfig?.requests?.additionalPrompts}
                    busy={busy}
                    tokenText={tokenText}
                    onAddToken={onAddToken}
                    onChangeToken={onChangeToken}
                    onChange={onChangeField}
                    onDelete={onDeleteAdditionalPrompt}
                    handleSubmit={handleSubmit}
                />
            )}
        </Box>
    );
}
