export interface GeneratePDFOptions {
    profile: string;
    document: string;
}

export interface GetPDFLinkOptions {
    profile: string;
    folder: string;
    file: string;
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "GET",
    "Access-Control-Allow-headers": "*"
};

export function downloadAnswersDocumentUrl({ profile, document }: GeneratePDFOptions): string {
    return `${process.env.REACT_APP_API_URL}/profile/${profile}/document/${document}/generate`;
}

export async function getPDFLink({ profile, folder, file }: GetPDFLinkOptions): Promise<string> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/${folder}/${file}/link`;
    const response = await fetch(url, { method: "GET", headers });
    const link = await response.json();
    return link.file;
}
