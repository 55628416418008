import React, { useEffect, useState } from "react";
import InputDialog from "./InputDialog";

import {
    Box,
    IconButton,
    Tooltip
} from "@mui/material";

import {
    CommentOutlined as CommentIcon,
    MarkUnreadChatAltOutlined as MarkedCommentIcon
} from "@mui/icons-material";

export interface Props {
    prompt?: string;
    placeholder: string;
    title: string;
    tooltip: string;
    onChange: (event: React.SyntheticEvent, value: string) => void;
}

export default ({ prompt, title, placeholder, tooltip, onChange }: Props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [prompt]);

    async function handleChange(event: React.SyntheticEvent, value: string): Promise<void> {
        event.preventDefault();
        onChange(event, value);
    }

    return (
        <Box>
            <Tooltip title={tooltip}>
                <IconButton onClick={() => setOpen(true)}>
                    {prompt ? <MarkedCommentIcon /> : <CommentIcon />}
                </IconButton>
            </Tooltip>
            <InputDialog
                title={title}
                open={open}
                multiline={true}
                rows={4}
                width={500}
                size="medium"
                defaultInput={prompt}
                placeholder={placeholder}
                onChange={(event, value) => handleChange(event, value)}
                onClose={() => setOpen(false)}
            />
        </Box>
    );
};