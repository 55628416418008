import {
    Card,
    CardContent,
    Grid,
    Typography,
} from "@mui/material";

import { 
    LightMode as LightModeIcon,
    ElectricBolt as ElectricBoltIcon,
    Warning as WarningIcon
} from "@mui/icons-material"

export default function ChatHome() {
    return (
        <>
            <Typography variant="h4" color="text.secondary" sx={{ mb: 4, textAlign: "center" }}>Get Started</Typography>
            <Typography variant="h5" color="text.secondary" sx={{ mb: 4, textAlign: "center" }}>RFP Responder AI</Typography>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
                <Grid item xs={2} sm={4} md={4}>
                    <LightModeIcon sx={{ marginLeft: 12 }}/>
                    <Typography sx={{ textAlign: "center", marginRight: 7 }}>Examples</Typography>
                    <Card sx={{ mt: 2, textAlign: "center", width: 220, height: 90 }}>
                        <CardContent> 
                            <Typography variant="body2" color="text.secondary">"What kind of product does the company offers ?"</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <ElectricBoltIcon sx={{ marginLeft: 12 }}/>
                    <Typography sx={{ textAlign: "center", marginRight: 7 }} >Capabilities</Typography>
                    <Card sx={{ mt: 2, textAlign: "center", width: 220, height: 90 }}>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">Remembers what user said earlier in the conversation</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <WarningIcon sx={{ marginLeft: 12 }}/>
                    <Typography sx={{ textAlign: "center", marginRight: 7 }}>Limitations</Typography>
                    <Card sx={{ mt: 2, textAlign: "center", width: 220, height: 90 }}>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">May occasionally generate incorrect information</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Card sx={{ mt: 2, textAlign: "center", width: 220, height: 90 }}>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">"Does any of the documents mention an answer for this question ?"</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Card sx={{ mt: 2, textAlign: "center", width: 220, height: 90 }}>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">Allows user to provide follow-up corrections</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Card sx={{ mt: 2, textAlign: "center", width: 220, height: 90 }}>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">Limited knowledge. Depends on the uploaded documents</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}