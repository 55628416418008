import { useMediaQuery, useTheme } from "@mui/material";

    export function useBreakpoint() {
        const theme = useTheme();
        const mq_xs = useMediaQuery(theme.breakpoints.only("xs"));
        const mq_sm = useMediaQuery(theme.breakpoints.only("sm"));
        const mq_md = useMediaQuery(theme.breakpoints.only("md"));
        const mq_lg = useMediaQuery(theme.breakpoints.only("lg"));
        const mq_xl = useMediaQuery(theme.breakpoints.only("xl"));

        function getBreakPointName(): "xs" | "sm" | "md" | "lg" | "xl" | undefined {
            if (mq_xs)
                return "xs"
            if (mq_sm)
                return "sm"
            if (mq_md)
                return "md"
            if (mq_lg)
                return "lg"
            if (mq_xl)
                return "xl"
        };
        
        const breakpoint = getBreakPointName();

    return {
        breakpoint
    }
};
