import React from "react";
import { useApp, useLabels } from "../DocumentFolder/context";
import LabelRibbon from "./LabelRibbon";

import {
    Box,
    SxProps,
    Theme
} from "@mui/material";

import {
    FilterAltOutlined as FilterIcon
} from "@mui/icons-material";

export interface Props {
    sx?: SxProps<Theme>;
}

export default ({ sx }: Props) => {
    const { filter, setFilter } = useApp();
    const { labels } = useLabels();
    const filteredLabels = labels?.filter(label => filter?.includes(label.id)) || [];
    return (
        <Box
            display={filter.length > 0 ? "block" : "none"}
            sx={sx}
        >
            <FilterIcon sx={{ mr: 1, position: "relative", top: 8 }} />
            <LabelRibbon
                labels={filteredLabels}
                display="inline"
                sx={{ position: "relative", top: 4 }}
                onDelete={(event, label) => {
                    event.stopPropagation();
                    setFilter(filter.filter(value => value !== label));
                }}
            />
        </Box>
    );
};
