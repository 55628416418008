import { useState } from "react";
import { useUser, useDocument, useDocumentPath } from "./context";
import { Document } from "./lib";

import DeleteButton from "./DeleteButton";
import Questions from "./Questions";
import OpenPDFButton from "./OpenPDFButton";
import Prompts from "./Prompts";
import ReloadButton from "./ReloadButton";
import Sections from "./Sections";
import Summary from "./Summary";

import {
    Alert,
    Chip,
    LinearProgress,
    Stack,
    Typography
} from "@mui/material";

import {
    Collapsible,
    DocumentBar,
    FilterBar,
    PDFViewer
} from "./components";

export default function DocumentPage() {
    const { user } = useUser();
    const { filename } = useDocumentPath();
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState("");

    const [doc, docLoading, docError] = useDocument(`/profiles/${user?.profile}/documents`, filename || "default");
    const document = { ...doc?.data(), id: doc?.id, profile: user?.profile } as unknown as Document;

    function handlePDFClose(): void {
        setOpen(false);
    }

    if (docLoading)
        return (
            <Stack>
                <DocumentBar>
                    <DeleteButton disabled={true} />
                </DocumentBar>
                <LinearProgress sx={{ mt: 2 }} />
            </Stack>
        );
    else if (docError)
        return (
            <Stack>
                <DocumentBar>
                    <DeleteButton />
                </DocumentBar>
                <Alert severity="error">{docError.message}</Alert>
            </Stack>
        );

    return (
        <Stack>
            <DocumentBar>
                <OpenPDFButton file={document.name} setFile={setFile} setOpen={setOpen} />
                <DeleteButton id={document.id} />
                <ReloadButton id={document.id} />
            </DocumentBar>

            <FilterBar />

            <Stack sx={{ mt: 2 }}>
                <Collapsible
                    title="Summary"
                    variant="outlined"
                    defaultExpanded
                >
                    <Summary document={document} />
                </Collapsible>

                {document.questions && (
                <Collapsible
                    title={<Typography>Questions <Chip label={document.questions} color="info" size="small" sx={{ ml: 1, color: "white" }} /></Typography>}
                    variant="outlined"
                    defaultExpanded
                >
                    <Questions document={document} />
                </Collapsible>
                )}

                <Collapsible
                    title={<Typography>Sections {document.sections && <Chip label={document.sections} color="info" size="small" sx={{ ml: 1, color: "white" }} />}</Typography>}
                    variant="outlined"
                >
                    <Sections document={document} />
                </Collapsible>

                {user?.admin && (
                <Collapsible
                    title="Prompts (admin only)"
                    variant="outlined"
                >
                    <Prompts document={document} />
                </Collapsible>
                )}
            </Stack>

            {file.includes(".pdf") && <PDFViewer open={open} file={file} handleClose={handlePDFClose}/>}
        </Stack>
    );
};
