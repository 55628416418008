export interface AskResult {
    ok: boolean;
    question: string;
    answer: string;
    prompt: string;
    promptAddition: string;
    hits: ReferenceDocument[];
    section: Section;
    usage: number;
    finish_reason: string;
    elapsed: {
        embedding: number;
        query: number;
        prompt: number;
    }
    response: string;
    info: string;
    chat: ChatMessage[];
};

export interface ReferenceDocument {
    file: string;
    heading: string;
    summary: string;
    page: string;
    timestamp: string;
    score: string;
    disabled?: boolean;
}

export interface Section {
    id: string;
    heading: string;
    text: string;
    page: number;
    summary: string;
    questions: Question[];
    elapsed: number;
    usage: number
}

export interface Question {
    id: string;
    question: string;
}

export interface ChatMessage {
    role: "system" | "user" | "assistant";
    content: string
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-headers": "*"
};

export async function ask(question: string, profile: string, chat: ChatMessage[], filter: string[]): Promise<AskResult | undefined> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/ask?q=${encodeURIComponent(question)}&filter=${filter.join(",")}`;
    const body = { chat };
    const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body)
    });
    if (!response.ok)
        return { ok: false } as AskResult; // todo: should throw instead
    const data = { ...await response.json() as AskResult, ok: true } ;
    return data;
}