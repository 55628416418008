import { AddUserTab } from "../Admin/ManageUsersDialog";

export interface UserRecord {
    users: FirebaseUser[];
}
export interface FirebaseUser {
    uid: string;
    displayName: string;
    email: string;
    phoneNumber: string;
    disabled: boolean;
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "*",
    "Access-Control-Allow-headers": "*"
};

export async function createUser(profile: string, email: string, provider: AddUserTab, name?: string, password?: string): Promise<FirebaseUser>{
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/user/create?email=${email}&name=${name}&provider=${provider}&password=${password}`;
    const response = await fetch(url, { method: "POST", headers });
    if (!response.ok)
        return {} as FirebaseUser; // todo: should throw instead
    const data = await response.json();
    return data; // todo: should throw instead
}

export async function editUser(profile: string, email: string, userId: string, name?: string, password?: string): Promise<boolean>{
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/user/edit?email=${email}&userId=${userId}&name=${name}&password=${password}`;
    const response = await fetch(url, { method: "POST", headers });
    return response.ok; // todo: should throw instead
}

export async function deleteUser(profile: string, uid: string): Promise<boolean> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/user/delete?uid=${uid}`;
    const response = await fetch(url, { method: "DELETE", headers });
    return response.ok; // todo: should throw instead
}

export async function getUsers(profile: string): Promise<UserRecord> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/admin/users`;
    const response = await fetch(url, { method: "GET", headers });
    if (!response.ok)
        return {} as UserRecord; // todo: should throw instead
    const data = await response.json();
    return data.users;
}
