import React from "react";
import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#004389", // dark blue
            //light: "#52affe", // light blue
            //dark: "#3b7cc5" // medium blue
        },
        secondary: {
            main: "#f91c3d", // red/orange
            light: "#fd94c9", // pink
            dark: "#f91c3d"
        },
        background: {
            default: "#fff" // white background color
        },
        text: {
            primary: "#595f6f", // dark text color
            secondary: "#004389" // dark blue
        },
        info: {
            main: "#29b6f6",
            light: "#4fc3f7",
            dark: "#0288d1"
        },
        success: {
            main: "#66bb6a",
            light: "#81c784",
            dark: "#388e3c"
        },
        error: {
            main: "#f44336",
            light: "#e57373",
            dark: "#d32f2f"
        },
        warning: {
            main: "#ffa726",
            light: "#ffb74d",
            dark: "#f57c00"
        }
    },
    typography: {
        fontFamily: "Ubuntu, sans-serif", // default font family used in the website
        h1: {
            fontWeight: 700,
        },
        h2: {
            fontWeight: 700,
        },
        h3: {
            fontWeight: 700,
        },
        h4: {
            fontWeight: 700,
        },
        h5: {
            fontWeight: 700,
        },
        h6: {
            fontWeight: 700,
        },
        button: {
            textTransform: "none", // Remove the default uppercase transformation on buttons
        }
    }
});
