import React from "react";
import { Document } from "./lib";

import {
    SxProps,
    Theme,
    Typography
} from "@mui/material";

export interface Props {
    document: Document;
    sx?: SxProps<Theme>;
}

export default ({ document, sx }: Props) => {
    let text = "";
    let color = undefined;
    if (document.status === "loading"  && document.page && document.pages) {
         text = `${document.page} of ${document.pages} pages read`;
         if (document.questions)
            text += `, ${document.questions} questions found`;
    }
    else if (document.status === "ok") {
        text = `${document.pages} pages${document.questions ? `, ${document.questions} questions` : ""}`;
    }
    else if (document.status === "error" || document.status === "aborted") {
        text = document.error;
        color = "error";
    }
    else {
        text = document.status;
    }

    return <Typography variant="subtitle2" color={color} noWrap sx={sx}>{text}</Typography>;
}