import React from "react";
import { useLocation } from "react-router-dom";
import Document from "./Document";
import DocumentFolder from "./DocumentFolder";
import DocumentRoot from "./DocumentRoot";

export default () => {
    const location = useLocation();
    if (!location.pathname.startsWith("/documents/"))
        return null;
    else if (location.pathname === "/documents/")
        return <DocumentRoot />;
    else if (location.pathname.endsWith("/"))
        return <DocumentFolder />;
    else
        return <Document />;
};
