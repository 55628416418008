import React from "react";
import AppBar from "./AppBar";
import { useMediaQuery } from "./components";

import {
    Container,
    CssBaseline,
    Paper
} from "@mui/material";

export interface Props {
    children: React.ReactNode;
}
export default function AppFrame({ children }: Props) {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
    return (
        <Container>
            <CssBaseline />
            <AppBar />
            <Container>
                <Paper
                    elevation={0}
                    sx={{
                        width: "100%",
                        maxWidth: 1200,
                        mt: 8,
                        p: 4
                    }}
                >
                    {children}
                </Paper>
            </Container>
        </Container>

    );
};
