import React from "react";
import { formatNumber, Document } from "./lib";

import {
    Stack,
    SxProps,
    Theme,
    Typography
} from "@mui/material";

export interface Props {
    document: Document;
    sx?: SxProps<Theme>;
}

export default ({ document, sx }: Props) => {
    const summary = document.summary || (document as any).abstract; // todo: remove abstract shim
    let text = `${document.pages} pages`;
    if (document.questions)
        text += `, ${document.questions} questions`;
    if (document.status === "loading")
        text += `, loading (${document.progress}%)`;

    return (
        <Stack sx={sx}>
            {summary && <Typography  sx={{ fontStyle: "italic" }}>{summary}</Typography>}
            <Typography sx={{ mt: 2 }}>{text}</Typography>
        </Stack>
    );
}