import React from "react";
import { useTheme } from "@mui/material/styles";

import {
    Stack,
    SxProps,
    Theme
} from "@mui/material";

import {
    SplitPane
} from "./components";

export interface Props {
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
}

export default ({ children, sx }: Props) => {
    const theme = useTheme();
    const [left, ...right] = React.Children.toArray(children);
    return (
        <SplitPane sx={{ bgcolor: "#eee", p: 1, mt: 2, mb: 0, ...sx }}>
            {left}
            <Stack direction="row">
                {right}
            </Stack>
        </SplitPane>
    );
}