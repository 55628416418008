import React, { useEffect, useState } from "react";
import { useLabels } from "./context";
import Label from "./Label";

import {
    Box,
    Button,
    Checkbox,
    MenuList,
    MenuItem,
    Popover,
    SxProps,
    TextField,
    Theme,
    Typography
} from "@mui/material";

export interface Props {
    anchorEl: HTMLElement | undefined;
    open: boolean;
    checked: string[];
    indeterminate: string[];
    onClose: (event: React.SyntheticEvent) => void;
    onChange: (event: React.SyntheticEvent, result: LabelEditorResult) => void;
    sx?: SxProps<Theme>;
}

export interface LabelEditorResult {
    checked: string[];
    unchecked: string[];
}

export default ({ anchorEl, open, sx, onChange, onClose, ...options }: Props) => {
    const [changed, setChanged] = useState(false);
    const [newName, setNewName] = useState("");
    const [checked, setChecked] = useState<string[]>([]);
    const [indeterminate, setIndeterminte] = useState<string[]>([]);
    const { labels, addLabel, deleteLabel } = useLabels();

    useEffect(() => {
        setChanged(false);
        setChecked(options.checked);
        setIndeterminte(options.indeterminate);
    }, [open]);

    async function handleAddLabel(event: React.SyntheticEvent) {
        const id = await addLabel(newName.trim());
        if (id) {
            setNewName("");
            setChecked([...checked, id]);
            setChanged(true);
        }
    }

    async function handleDeleteLabel(id: string) {
        setChecked(checked.filter(value => value !== id));
        setIndeterminte(indeterminate.filter(value => value !== id));
        setChanged(true);
        await deleteLabel(id);
    }

    function handleClose(event: React.SyntheticEvent): void {
        if (changed) {
            const keys = labels.map(label => label.id);
            const result = {
                checked: [] as string[],
                unchecked: [] as string[]
            };
            for (const key of keys) {
                const state = checked.includes(key) ? "checked" : indeterminate.includes(key) ? "indeterminate" : "unchecked";
                if (state === "checked" && (!options.checked.includes(key) || options.indeterminate.includes(key)))
                    result.checked.push(key);
                else if (state === "unchecked" && (options.checked.includes(key) || options.indeterminate.includes(key)))
                    result.unchecked.push(key);
            }
            if (result.checked.length > 0 || result.unchecked.length > 0)
                onChange(event, result);
        }
        onClose(event);
    }

    function toggleChecked(id: string): void {
        if (checked.includes(id)) {
            setChecked(checked.filter(value => value !== id));
        }
        else if (indeterminate.includes(id)) {
            setChecked([...checked, id]);
            setIndeterminte(indeterminate.filter(value => value !== id));
        }
        else {
            setChecked([...checked, id]);
        }
        setChanged(true);
    }

    return (
        <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: "left",
                vertical: "bottom"
            }}
            sx={sx}
        >
            <Box>
                <TextField
                    variant="standard"
                    placeholder="Type a label name"
                    size="small"
                    value={newName}
                    onChange={event => setNewName(event.target.value)}
                    sx={{ m: 1 }}
                />
            </Box>
            <Box
                sx={{
                    p: 1,
                    pl: 2,
                    backgroundColor: "#eee",
                    display: newName.trim() && !labels.map(label => label.name).includes(newName.trim()) ? "block": "none"
                }}
            >
                <Label label={{ name: newName.trim() }} />
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={handleAddLabel}
                >
                    <Typography fontSize="small">Create label</Typography>
                </Button>
            </Box>
            <Box
                style={{
                    minHeight: "100px",
                    maxHeight: "900px",
                    overflow: "auto"
                }}
            >
                <MenuList>
                    {labels
                        .filter(label => label.name.toLowerCase().includes(newName.toLowerCase()))
                        .map(label => (
                            <MenuItem
                                key={label.id}
                                onClick={() => toggleChecked(label.id)}
                                sx={{ p: 0 }}
                            >
                                <Checkbox
                                    size="small"
                                    checked={checked.includes(label.id)}
                                    indeterminate={indeterminate.includes(label.id)}
                                    onClick={() => toggleChecked(label.id)}
                                    sx={{ display: "flex", alignItems: "flex-start" }}
                                />
                                <Label
                                    label={label}
                                    onDelete={() => handleDeleteLabel(label.id)}
                                />
                            </MenuItem>
                        )
                    )}
                </MenuList>
            </Box>
        </Popover>
    );
}