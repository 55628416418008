import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useUser } from "./context";
import { deleteDocument } from "./lib";
import { ConfirmDialog } from "./components";

export interface Props {
    id?: string;
    disabled?: boolean;
}

export default ({ id, disabled }: Props) => {
    const { user } = useUser();
    const [busy, setBusy] = useState(false);
    const [prompt, setPrompt] = useState(false);
    const navigate = useNavigate();

    async function handleDelete() {
        setBusy(true);
        if (id && user?.profile) {
            try {
                await deleteDocument({ profile: user.profile, id });
                navigate(-1); // navigate to the parent folder for the document
            }
            catch (err) {
                console.error(err);
            }
        }
        setBusy(false);
    }

    return (
        <>
            <Tooltip title="Delete this file">
                <LoadingButton
                    variant="text"
                    loading={busy}
                    disabled={disabled}
                    onClick={() => setPrompt(true)}
                >
                    <DeleteIcon fontSize="small" />
                </LoadingButton>
            </Tooltip>
            <ConfirmDialog
                title="Delete File"
                message="Are you sure you want to delete this file?"
                open={prompt}
                onClose={(event, confirmed) => {
                    setPrompt(false);
                    if (confirmed)
                        handleDelete();
                }}
            />
        </>
    );
};
