import React, { useState } from "react";
import { Link } from "react-router-dom";
import DocumentBar from "./DocumentBar";
import DocumentIcon from "./DocumentIcon";
import DocumentSubtitle from "./DocumentSubtitle";

import {
    Alert,
    Box,
    Checkbox,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";

import {
    useApp,
    useCollection,
    useDocumentPath,
    useLabels,
    useUser
} from "./context";

import {
    createDictionary,
    updateDocument,
    Document
} from "./lib";

import {
    DocumentBreadcrumbs,
    FileUploadButton,
    FilterBar,
    LabelButton,
    LabelRibbon,
    LabelEditorResult
} from "./components";

export default () => {
    const { folder } = useDocumentPath();
    const { user } = useUser();
    const { filter, setFilter } = useApp();
    const { labels } = useLabels();
    const [collection, collectionLoading, collectionError] = useCollection(`/profiles/${user?.profile}/documents`);
    const [uploadError, setUploadError] = useState("");
    const [checked, setChecked] = useState<string[]>([]);

    const documents = collection?.docs
        .map(doc => ({ labels: [] as string[], ...doc.data(), id: doc.id } as Document))
        .filter(doc => doc.folder === folder)
        .sort((a, b) => a.name?.localeCompare(b.name)) || [];

    const error = collectionError?.message || uploadError;
    const allChecked = checked.length === documents.length;

    const labelMap = createDictionary(labels, "id");
    const labelsActive = folder === "responses";
    const filteredDocuments = labelsActive ? documents.filter(document => filter.length === 0 || filter.every(key => document.labels?.includes(key))) : documents;
    const selectedDocuments = filteredDocuments.filter(document => checked.includes(document.id));
    const allDocumentLabels = Array.from(new Set(selectedDocuments.map(document => document.labels).flat()));
    const labelsThatEveryDocumentHas = allDocumentLabels.filter(label => selectedDocuments.every(document => document.labels?.includes(label)));
    const labelsThaNotEveryDocumentHas = allDocumentLabels.filter(label => !selectedDocuments.every(document => document.labels?.includes(label)));

    async function applyLabels(event: React.SyntheticEvent, result: LabelEditorResult): Promise<void> {
        const selectedDocuments = documents.filter(document => checked.includes(document.id));
        for (const document of selectedDocuments) {
            if (result.checked.length > 0)
                document.labels = Array.from(new Set([...document.labels, ...result.checked]));
            if (result.unchecked.length > 0)
                document.labels = document.labels.filter(label => !result.unchecked.includes(label));
            await updateDocument({
                collection: `/profiles/${user?.profile}/documents`,
                id: document.id,
                data: { labels: document.labels }
            })
        }
    }

    function toggleChecked(id: string): void {
        if (checked.includes(id))
            setChecked(checked.filter(c => c !== id));
        else
            setChecked([...checked, id]);
    }

    if (collectionLoading) {
        return (
            <Stack>
                <DocumentBreadcrumbs />
                <LinearProgress sx={{ mt: 2 }} />
            </Stack>
        );
    }
    else if (error) {
        return (
            <Stack>
                <DocumentBreadcrumbs />
                <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>
            </Stack>
        );
    }
    else if (!user?.profile) {
        return (
            <Stack>
                <DocumentBreadcrumbs />
                <Alert severity="error" sx={{ mt: 2 }}>No profile set</Alert>
            </Stack>
        );
    }
    else if (!folder) {
        return (
            <Stack>
                <DocumentBreadcrumbs />
                <Alert severity="error" sx={{ mt: 2 }}>Invalid folder</Alert>
            </Stack>
        );
    }
    else if (documents.length === 0) {
        return (
            <Stack>
                <DocumentBreadcrumbs />
                <Alert severity="info" sx={{ mt: 2 }}>No documents uploaded yet.</Alert>
                <br />
                <FileUploadButton folder={folder} onError={(error, message) => setUploadError(message)} />
            </Stack>
        );
    }

    return (
        <Stack>
            <DocumentBreadcrumbs />

            <DocumentBar>
                <Box>
                    <FilterBar />
                </Box>
                {labelsActive && <LabelButton
                    checked={labelsThatEveryDocumentHas}
                    indeterminate={labelsThaNotEveryDocumentHas}
                    disabled={selectedDocuments.length === 0}
                    onChange={applyLabels}
                />}
                <FileUploadButton folder={folder} onError={(error, message) => setUploadError(message)} sx={{ ml: 1 }} />
            </DocumentBar>
            
            <List>
                <ListItem disablePadding>
                    <Checkbox
                        size="small"
                        checked={allChecked}
                        onClick={() => allChecked ? setChecked([]) : setChecked(documents.map(document => document.id))}
                        sx={{ display: "flex", alignItems: "flex-start" }}
                    />
                    <ListItemText>{!allChecked ? "Select all" : "Select none"}</ListItemText>
                </ListItem>
                {filteredDocuments.map(document => (
                    <ListItem 
                        key={document.id} 
                        disablePadding
                        //secondaryAction={ !!questions && !!document?.questions && <Typography>{document.questions.length}</Typography> }
                    >
                        <Checkbox
                            size="small"
                            checked={checked.includes(document.id)}
                            onClick={() => toggleChecked(document.id)}
                            sx={{ display: "flex", alignItems: "flex-start" }}
                        />
                        <ListItem>
                            <ListItemAvatar>
                                <DocumentIcon document={document} />
                            </ListItemAvatar>
                            <Typography noWrap>
                                <Link to={`/documents/${folder}/${document.id}`} style={{ textDecoration: "none", color: "inherit" }}>{document.name}</Link>
                                <DocumentSubtitle document={document} />
                                <LabelRibbon
                                    labels={document.labels?.map(key => labelMap[key]).filter(obj => !!obj)}
                                    onClick={(event, label) => {
                                        event.stopPropagation();
                                        if (filter.includes(label))
                                            setFilter(filter.filter(value => value !== label));
                                        else
                                            setFilter([...filter, label]);
                                    }}
                                />
                            </Typography>
                        </ListItem>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
};
