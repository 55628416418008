import { useUser } from "../context";
import { useDocument } from "./hooks";
import { firestore as db } from "../firebase";
import * as firestore from "firebase/firestore";

export interface AskConfig {
    defaultNamespace: string;
    maxPromptHits: number;
    model: string;
    persona: string;
    prompt: string;
    additionalPrompts: string[];
    temperature: number;
    topK: number;
}

export interface ResponsesConfig {
    model: string;
    persona: string;
    prompt: string;
    additionalPrompts: string[];
    temperature: number;
}

export interface RequestsConfig extends ResponsesConfig {
    enabled: boolean;
}

export interface CloudConfig {
    answer: { concurrency: number} ;
    ask: AskConfig;
}

export interface ImporterConfig {
    concurrency: number;
    requests: RequestsConfig;
    responses: ResponsesConfig;
}

export interface Config {
    cloud: CloudConfig;
    importer: ImporterConfig;
}

export function useAdmin() {
    const { user } = useUser();

    const [profileCloudDoc] = useDocument(`profiles/${user?.promptAdmin || user?.profile}/config`, "cloud");
    const profileCloud = profileCloudDoc ? { ...profileCloudDoc.data() } as CloudConfig : undefined;
    const [profileImporterDoc] = useDocument(`profiles/${user?.promptAdmin || user?.profile}/config`, "importer");
    const profileImporter = profileImporterDoc ? { ...profileImporterDoc.data() } as ImporterConfig : undefined;

    const [cloudDoc] = useDocument("config", "cloud");
    const cloud = cloudDoc ? { ...cloudDoc.data() } as CloudConfig : undefined;
    const [importerDoc] = useDocument("config", "importer");
    const importer = importerDoc ? { ...importerDoc.data() } as ImporterConfig : undefined;

    const baseConfig = { cloud, importer } as Config;
    const config = { cloud: profileCloudDoc?.exists() ? profileCloud : cloud, importer: profileImporterDoc?.exists() ? profileImporter: importer } as Config;

    async function initialSetup(profile: string, config: ImporterConfig | CloudConfig, docId: "cloud" | "importer") {
        const doc = firestore.doc(db, `profiles/${profile}/config`, docId);
        await firestore.setDoc(doc, { ...config }, { merge: true });
    }

    async function updatePrompt(profile: string, type: string, subType: string, persona?: string, prompt?: string) {
        const doc = firestore.doc(db, `profiles/${profile}/config`, type);
        await firestore.setDoc(doc, { [subType]: {
                persona: persona && persona.length >= 1 ? persona : firestore.deleteField(),
                prompt: prompt && prompt.length >= 1  ? prompt : firestore.deleteField(),
            }
        }, { merge: true });
    }

    async function updateBasePrompt(type: string, subType: string, persona: string, prompt: string) {
        const doc = firestore.doc(db, `config`, type);
        if (type === "cloud")
            await firestore.setDoc(doc, { ask: { persona, prompt } }, { merge: true });
        else
            await firestore.setDoc(doc, { [subType]: { persona, prompt } }, { merge: true });
    }

    async function updateRequestFeature(profile: string, enabled: boolean) {
        const doc = firestore.doc(db, `profiles/${profile}/config`, "importer");
        await firestore.setDoc(doc, { requests: { enabled } }, { merge: true });
    }

    async function updatePromptTokens(profile: string, type: string, subType: string, additionalPrompts?: string[]) {
        const doc = firestore.doc(db, `profiles/${profile}/config`, type);
        if (type === "cloud")
            await firestore.setDoc(doc, { ask: { 
                additionalPrompts: additionalPrompts && additionalPrompts.length >= 1 ? additionalPrompts : firestore.deleteField()
            } }, { merge: true });
        else
            await firestore.setDoc(doc, { [subType]: { 
                additionalPrompts: additionalPrompts && additionalPrompts.length >= 1 ? additionalPrompts : firestore.deleteField()
            } }, { merge: true });
    }

    return {
        profileCloud,
        profileCloudDoc,
        profileImporter,
        profileImporterDoc,
        cloud,
        cloudDoc,
        importer,
        importerDoc,
        baseConfig,
        config,
        initialSetup,
        updatePrompt,
        updateBasePrompt,
        updateRequestFeature,
        updatePromptTokens
    }
}