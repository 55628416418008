export interface AnswerResult {
    ok: boolean;
}

export interface AnswerOptions {
    questions: string[];
    profile: string;
    document: string;
    filter?: string[]
    promptAddition?: string;
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-headers": "*"
};

export async function answer({ questions, profile, document, filter }: AnswerOptions): Promise<AnswerResult> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/document/${document}/answers`;
    const body = {
        q: questions.join(","),
        filter: filter ? filter.join(",") : undefined
    };
    const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(body) });
    return { ok: response.ok }
}

export function downloadAnswersDocumentUrl({ profile, document }: { profile: string, document: string }): string {
    return `${process.env.REACT_APP_API_URL}/profile/${profile}/document/${document}/generate`;
}

export function formatPageNumber(value: unknown): string {
    if (value instanceof Array)
        return value[0];
    else if (typeof value === "string")
        return value;
    else
        return "";
}

export function formatQuestionText(value: string): string {
    return value.replace(/^(\d{1,3}\.)|[-]/, "").trim();
}