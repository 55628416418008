import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { firestore as db } from "./firebase";
import * as firestore from "firebase/firestore";

export interface AppState {
    authenticated: boolean;
    userId?: string;
    sideBarOpen: boolean;
    setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    filter: string[];
    setFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

export const AppContext = React.createContext<AppState>({
    authenticated: false,
    userId: "",
    sideBarOpen: false,
    setSideBarOpen: () => {},
    filter: [],
    setFilter: () => {}
});

export function AppProvider({ children }: { children: React.ReactNode }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [userId, setUserId] = useState(user?.uid);
    const [authenticated, setAuthenticated] = useState(!!user?.uid);
    const [filter, setFilter] = useState<string[]>([]);

    useEffect(() => {
        if (user?.uid) {
            setUserId(user.uid);
            setAuthenticated(true);
            (async () => {
                const doc = firestore.doc(db, "users", user.uid);
                await firestore.setDoc(doc, { lastSeen: new Date() }, { merge: true });
            })();
        }
        else {
            setUserId("");
            setAuthenticated(false);
            navigate("/login");
        }
    }, [user, navigate]);

    const value = {
        authenticated,
        sideBarOpen,
        setSideBarOpen,
        userId,
        filter,
        setFilter
    };

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
}

export function useApp() {
    return useContext(AppContext);
}
