import React from "react";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from './AuthContext';
import { AppProvider } from "./AppContext";
import { theme } from "./theme";

import Admin from "./Admin";
import AppFrame from "./AppFrame";
import Login from "./Login/index"
import DocumentPath from "./DocumentPath";
import Chat from "./Chat";

export default function App() {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <AppProvider>
                        <AppFrame>
                            <Routes>
                                <Route path="/" element={<Chat />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/admin" element={<Admin />} />
                                <Route path="/documents/*" element={<DocumentPath />} />
                            </Routes>
                        </AppFrame>
                    </AppProvider>
                </AuthProvider>
            </ThemeProvider>
        </Router>
    );
};
