import React from "react";
import AnswerDetails from "./AnswerDetails";
import { useUser } from "./context";
import { downloadAnswersDocumentUrl, formatQuestionText } from "./lib";
import { Collapsible } from "./components";
import { QuestionDocument } from ".";

import {
    Box,
    Button,
    Chip,
    Grid,
    Stack,
    Typography
} from "@mui/material";

export interface Props {
    docId: string;
    questions: QuestionDocument[];
}

export default function ({ docId, questions }: Props) {
    const { user } = useUser();
    const profile = user?.profile || "";
    return (
        <Stack>
            {questions.length > 0 && (
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, mb: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    href={downloadAnswersDocumentUrl({ profile, document: docId })}
                >
                    <Typography>Download PDF</Typography>
                </Button>
            </Box>
            )}

            {questions.map(question => (
                <Collapsible
                    key={question.id}
                    variant="outlined"
                    title={
                        <Grid container spacing={2}>
                            <Grid item md xs>
                                <Typography>{formatQuestionText(question.question)} {question.page ? `(page ${question.page})`: ""}</Typography>
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Chip
                                    variant="filled"
                                    size="small"
                                    label={`${question.hits.length}`}
                                    color={question.hits.length > 0 ? "success" : "error"}
                                />
                            </Grid>
                        </Grid>
                    }
                >
                    <AnswerDetails
                        profile={profile}
                        data={question}
                        docId={docId}
                        questionId={question.id}
                        showQuestion={false}
                    />
                </Collapsible>
            ))}
        </Stack>
    );
}
