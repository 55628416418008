import React, { useState, useEffect } from "react";
import { User, useAdmin, useUser } from "../../context";

import {
    Box,
    Stack,
    Tab,
    Tabs,
    Typography,
    SelectChangeEvent,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import ProfileSelector from "../ProfileSelector";
import Responses from "./Responses";
import Requests from "./Requests";
import Answer from "./Answer";

export interface PromptDialogProps {
    open: boolean;
    user: User;
}

type PromptTab = "ask" | "responses" | "requests" | undefined;

export default function Prompts({ open, user }: PromptDialogProps) {
    const { baseConfig, updatePromptTokens, updatePrompt} = useAdmin(); 
    const { setUserAdmin } = useUser();
    const [showTab, setShowTab] = useState<PromptTab>("ask");
    const [promptAdmin, setPromptAdmin] = useState(user.promptAdmin || user.profile);
    const [tab, setTab] = useState<number>(0)
    const [busy, setBusy] = useState(false);
    const extraProfile = { id: "(All Profiles)", name: "(All Profiles)" };

    useEffect(() => {
        setShowTab("ask");
    }, [promptAdmin])

    function handleTab(event: React.SyntheticEvent, newValue: number) {
        setTab(newValue);
    }

    function onChangeProfile(event: SelectChangeEvent<string>, setValue:React.Dispatch<React.SetStateAction<string | undefined>>) {
        setPromptAdmin(event.target.value);
        setValue(event.target.value);
        setUserAdmin(event.target.value);
        setTab(0);
        setShowTab(undefined);
    }

    async function resetPrompts() {
        setBusy(true);
        const configDoc = showTab === "ask" ? "cloud" : "importer"
        const resetProps = { persona: "", prompt: "", additionalPrompts: [] as string[] } 
        if (configDoc === "cloud") {
            resetProps.persona = baseConfig?.cloud?.ask?.persona;
            resetProps.prompt = baseConfig?.cloud?.ask?.prompt;
            resetProps.additionalPrompts = baseConfig?.cloud?.ask?.additionalPrompts
        }
        if (configDoc === "importer" && (showTab === "requests" || showTab === "responses")) {
            resetProps.persona = baseConfig?.importer?.[showTab]?.persona;
            resetProps.prompt = baseConfig?.importer?.[showTab]?.prompt;
            resetProps.additionalPrompts = baseConfig?.importer?.[showTab]?.additionalPrompts
        }
        if (promptAdmin) {
            await updatePromptTokens(promptAdmin, configDoc, showTab as string, resetProps.additionalPrompts)
            await updatePrompt(promptAdmin, configDoc, showTab as string, resetProps.persona, resetProps.prompt);
        }
        setBusy(false);
    }

    return (
        <Box>
            {open && (
                <>
                    <Stack direction="row">
                        <ProfileSelector extraProfile={extraProfile} profile={promptAdmin} onChange={onChangeProfile}/>
                    </Stack>
                    <Tabs value={tab} onChange={handleTab}>
                        <Tab label="Answer" value={0} onClick={() => setShowTab("ask")} />
                        <Tab label="Responses" value={1} onClick={() => setShowTab("responses")} />
                        <Tab label="Requests" value={2} onClick={() => setShowTab("requests")} />
                    </Tabs>
                    <br/>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Edit Prompts</Typography>
                        {promptAdmin !== "(All Profiles)" && (
                            <LoadingButton
                                id="prompt"
                                variant="contained"
                                onClick={resetPrompts}
                                loading={busy}
                            >
                                Reset Prompts
                            </LoadingButton>
                        )}
                    </Stack>
                    {showTab === "ask" && (
                        <Answer busy={busy} promptAdmin={promptAdmin} setBusy={setBusy} />
                    )}
                    {showTab === "responses" && (
                        <Responses busy={busy} promptAdmin={promptAdmin} setBusy={setBusy} />
                    )}
                    {showTab === "requests" && (
                        <Requests busy={busy} promptAdmin={promptAdmin} setBusy={setBusy} />
                    )}
                </>
            )}
        </Box>
    );
}
