import { updateDocuments } from "../firestore";

export interface HideQuestionsOptions {
    profile: string;
    docId: string;
    questions: string[];
}

export async function hideQuestions({ profile, docId, questions }: HideQuestionsOptions): Promise<void> {
    await updateDocuments({
        collection: `/profiles/${profile}/documents/${docId}/questions`,
        ids: questions,
        data: { hidden: true }
    });
}
