import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useUser } from "./context";
import logo from "./assets/images/ikaun-logo-small-200.png";

import {
    AppBar as Bar,
    Box,
    Button,
    Container,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Typography
} from "@mui/material";

import {
    ExitToAppOutlined as LogoutIcon
} from "@mui/icons-material";
import ProfileSelector from "./Admin/ProfileSelector";

export default function AppBar() {
    const location = useLocation();
    const { logout } = useAuth();
    const { user, profile, setUserProfile } = useUser();

    const tab = (function () {
        if (location.pathname === "/" || location.pathname === "/login")
            return 0;
        else if (location.pathname.startsWith("/documents/requests"))
            return 1;
        else if (location.pathname.startsWith("/documents/responses"))
            return 2;
        else if (location.pathname.startsWith("/admin"))
            return 3;
        else
            return -1;
    })();

    async function handleLogout(): Promise<void> {
        await logout();
    }

    function onChangeProfile(event: SelectChangeEvent<string>) {
        setUserProfile(event.target.value)
    }

    if (user)
        return (
            <Bar
                color="inherit"
                component="nav"
                position="fixed"
                elevation={0}
            >
                <Box>
                    <Container>
                        <Toolbar>
                            <img src={logo} width={64} alt="" />        
                                <Tabs value={tab} variant="scrollable" sx={{ ml: 4 }}>
                                    <Tab label="Home" value={0} component={Link} to="/" />
                                    {profile?.requests?.enabled && <Tab label="Requests" value={1} component={Link} to="/documents/requests/" />}
                                    <Tab label="Responses" value={2} component={Link} to="/documents/responses/" />
                                    {user.admin && <Tab label="Admin" value={3} component={Link} to="/admin/" />}
                                </Tabs>
                                <Box flexGrow={0.7} />
                                <Stack direction="row">
                                    {(user.admin || (user.profiles && user.profiles?.length > 1)) && (
                                        <ProfileSelector onChange={onChangeProfile} userProfiles={user.profiles} />
                                    )}
                                    {!user.admin && !(user.profiles && user.profiles?.length > 1) && (
                                        <Typography noWrap display="block" fontSize="small" sx={{ mr: 2, mt: 1 }}>{user.profile}</Typography>
                                    )}
                                    {user && (
                                        <Button
                                            variant="text"
                                            onClick={handleLogout}
                                            startIcon={<LogoutIcon fontSize="medium"/>}
                                        >
                                            <Typography noWrap fontSize="small" >Sign out</Typography>
                                        </Button>
                                    )}
                                </Stack>
                        </Toolbar>
                    </Container>
                </Box>
            </Bar>
        );
    else
        return null;
};
