export interface ReloadDocumentResult {
    ok: boolean;
}

export interface ReloadDocumentOptions {
    profile: string;
    id: string;
}

const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-headers": "*"
};

export async function reloadDocument({ profile, id }: ReloadDocumentOptions): Promise<ReloadDocumentResult> {
    const url = `${process.env.REACT_APP_API_URL}/profile/${profile}/document/${id}`;
    const body = JSON.stringify({ status: "pending" });
    const response = await fetch(url, { method: "POST", body, headers });
    return { ok: response.ok }
}
