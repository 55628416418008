import React, { useState } from "react";
import LabelEditor, { LabelEditorResult } from "./LabelEditor";

import {
    Box,
    Button,
    SxProps,
    Theme,
    Typography
} from "@mui/material";

import {
    ExpandMore as MoreIcon
} from "@mui/icons-material";

export interface Props {
    checked: string[],
    indeterminate: string[],
    disabled?: boolean;
    onChange: (event: React.SyntheticEvent, result: LabelEditorResult) => void;
    sx?: SxProps<Theme>;
}

export default ({ checked, indeterminate, disabled, onChange, sx }: Props) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();

    return (
        <Box sx={sx}>
            <Button
                variant="outlined"
                disabled={disabled}
                onClick={event => {
                    setOpen(!open);
                    setAnchorEl(event.currentTarget);
                }}
            >
                <Typography>Label</Typography>
                <MoreIcon fontSize="small" />
            </Button>
            <LabelEditor
                anchorEl={anchorEl}
                open={open}
                checked={checked}
                indeterminate={indeterminate}
                onChange={onChange}
                onClose={() => setOpen(false)}
            />
        </Box>
    );
}