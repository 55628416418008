import React, { useState } from "react";
import { formatQuestionText } from "./lib";
import { QuestionDocument } from ".";
import AnswerQuestionsButton from "./AnswerQuestionsButton";
import DeleteQuestionsButton from "./DeleteQuestionsButton";

import {
    Alert,
    Box,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material";

export interface Props {
    docId: string;
    questions: QuestionDocument[];
}

export default function ({ docId, questions }: Props) {
    const [checked, setChecked] = useState<string[]>([]);
    const [error, setError] = useState("");
    const [selectAll, setSelectAll] = useState(false);

    function toggle(id: string): void {
        if (checked.includes(id))
            setChecked(checked.filter(c => c !== id));
        else
            setChecked([...checked, id]);
    }

    function toggleAll(): void {
        const questionsIds = questions.map(question => question.id);
        setChecked([...questionsIds]);
        setSelectAll(true);
    }

    function untoggleAll(): void {
        setChecked([]);
        setSelectAll(false);
    }

    return (
        <Stack>
            <Alert severity="error" sx={{ display: error ? "block" : "none" }}>{error}</Alert>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                <DeleteQuestionsButton
                    docId={docId}
                    questions={checked}
                />
                <AnswerQuestionsButton
                    docId={docId}
                    questions={checked}
                    onChange={() => {}}
                    onError={(event, error) => setError(error)}
                    sx={{ ml: 1 }}
                />
            </Box>
            <List>
                <ListItem>
                    <ListItemButton>
                        <Checkbox
                            size="small"
                            checked={selectAll} onClick={() => selectAll ? untoggleAll() : toggleAll()}
                            sx={{ display: "flex", alignItems: "flex-start" }}
                        />
                        <ListItemText>{selectAll ? "Select none" : "Select all"}</ListItemText>
                    </ListItemButton>
                </ListItem>
                {questions.map(q => (
                    <ListItem key={q.id}>
                        <ListItemButton
                            onClick={() => toggle(q.id)}
                            sx={{ display: "flex", alignItems: "flex-start" }}
                        >
                            <Checkbox
                                size="small"
                                checked={checked.includes(q.id)}
                                onClick={() => toggle(q.id)}
                                sx={{ position: "relative", top: -6 }}
                            />
                            <Typography>{formatQuestionText(q.question)} {q.page ? `(page ${q.page})`: ""}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}
